import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables } from '../constants';
import { DynamicComponentCreateService } from '../gcs.business.services/dynamic-component-create.service';
import { CustomerRegistationService } from '../customer-registation.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class GcsGraphQlCustomerRegistrationFirstService {
  stateCountryCode: any;

  constructor(private apollo: Apollo,
    public service: DynamicComponentCreateService,
    public customerRegistationService: CustomerRegistationService,
    public router: Router) { }

  public getCustomerRegistrationEmail(email: any): any {
    let customerRegistrationEmailQuery: QueryRef<any>;
    // get the fed Id and stick in inside line 22 deferated
    customerRegistrationEmailQuery = this.apollo.watchQuery({
      query: gql`
      query {
        emailValidation(input: {email: "${email}"}) {
          emailExistence
        }
      }
    `,
    });
    customerRegistrationEmailQuery.valueChanges.subscribe((result) => {
      const resultEmail = result && result.data;
      const emailData = resultEmail && resultEmail.emailValidation && resultEmail.emailValidation.emailExistence;

      sessionStorage.setItem(SessionVariables.customerRegistrationEmail, JSON.stringify(emailData));
      this.service.customerEmail.next(true);
      this.customerRegistationService.customerEmailstatusReceived.next(Math.random());
    });
  }

  public getCustomerRegistrationLocation(): any {
    let customerRegistrationLocationQuery: QueryRef<any>;
    // get the fed Id and stick in inside line 22 deferated
    customerRegistrationLocationQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getLocationDetails {
        countryCode
        countryDesc
        countryISOCode
        stateCode
        stateName
        stateNameShort
        countryPhoneExt
      }
    }
    `,
    });
    customerRegistrationLocationQuery.valueChanges.subscribe((result) => {
      const resultData = result && result.data;
      const locationData = resultData && resultData.getLocationDetails;
      const filterCountryCode = [...new Set(locationData)];
      sessionStorage.setItem(SessionVariables.customerRegistrationLocation, JSON.stringify(filterCountryCode));
      this.service.customerLocation.next(true);
    });
  }

  public getCustomerRegistrationStateLocation(countryCode: any): any {
    let customerRegistrationStateLocationQuery: QueryRef<any>;
    // get the fed Id and stick in inside line 22 deferated
    customerRegistrationStateLocationQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getLocationDetails(input: {countryCode: ${countryCode}}) {
          stateCode
          stateName
          stateNameShort
        }
      }
    `,
    });
    customerRegistrationStateLocationQuery.valueChanges.subscribe((result) => {
      const resultStateData = result && result.data;
      const locationStateData = resultStateData && resultStateData.getLocationDetails;
      sessionStorage.setItem(SessionVariables.customerRegistrationStateLocation, JSON.stringify(locationStateData));
      this.service.stateLocation.next(true);
    });
  }


  public getCustomerRegistrationProductFamily(): any {
    let customerRegistrationProductFamilyQuery: QueryRef<any>;
    customerRegistrationProductFamilyQuery = this.apollo.watchQuery({
      query: gql`
        query MyQuery {
          getProductFamily {
            productFamilyList {
              brandCode
              brandName
              userCount
            }
          }
        } `,
    });
    customerRegistrationProductFamilyQuery.valueChanges.subscribe((result) => {
      const resultProductData = result && result.data;
      const productData = resultProductData && resultProductData.getProductFamily;
      const productFamilyData = productData && productData.productFamilyList;
      sessionStorage.setItem(SessionVariables.customerRegistrationProductFamily, JSON.stringify(productFamilyData));
      this.service.customerProductFamily.next(true);
    });
  }

  public customerRegistrationSubmit(formData: any): any {
    //GW-2488 - taking out hardcoded [1,4] for brandCodeList
    this.apollo.mutate({
      mutation: gql`
      mutation MyMutation {
        customerRegistration(input:
          {
              adminComment: "",
              brandCodeList: [${formData.brandCodeList}],
              companySite: "${formData.companySite}",
              countryCode: ${formData.countryCode},
              epush: 0,
              levelCode: 0,
              stateCode: ${formData.stateCode},
              userAddress: "${formData.userAddress}",
              userCity: "${formData.userCity}",
              userCompany: "${formData.userCompany}",
              userEmail: "${formData.userEmail}",
              userFax: "",
              userGroup: 0,
              userJobTitle: "",
              userName: "${formData.userName}",
              userPhone: "${formData.userPhone}",
              userPhoneExt: "${formData.userPhoneExt}",
              userPostalCode: "${formData.userPostalCode}",
              userSurname: "${formData.userSurname}",
              dateProcessed: null,
              dateRequested: null,
              lastVisitedDate: null
              }
          ) {
          message
          notificationId
          user_code
        }
      }
  `,
    }).subscribe((response: any) => {
      if (response?.data?.customerRegistration?.message === 'Success') {
        this.router.navigate(['/home/confirmation-message']);
      }
      if (response?.errors) {
        sessionStorage.setItem(SessionVariables.registrationResponseMsg, response.errors[0].message);
      }
      this.service.customerRegistrationSubmit.next(true);
    });
  }
}
