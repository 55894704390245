import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables } from '../constants';

@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlOverViewUserInfoService {

  constructor(private apollo: Apollo) { }

  public getOverviewUserInfo(): any {
    let overViewUserInfoQuery: QueryRef<any>;
    overViewUserInfoQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getIDMSDetails{
        CFID
        companyName
        userName
        companyName
        userEntitlement
        }
        }
      `,
    });
    overViewUserInfoQuery.valueChanges.subscribe((result) => {
      sessionStorage.setItem(SessionVariables.overViewUserInfo, JSON.stringify(result.data.getIDMSDetails));
    });

    return JSON.parse(sessionStorage.getItem(SessionVariables.overViewUserInfo));
  }

}
