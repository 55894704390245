import { Apollo, QueryRef, gql } from 'apollo-angular';
import { Injectable, OnDestroy } from '@angular/core';
import { SessionVariables, screenIdsForTelemetry, ErrorCodes } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GcsGraphQlsetbBfoLogsServiceSecure } from './gcs.graph-ql.gcs-set-bfoLogs.service.secure';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlOverViewInfoServiceSecure implements OnDestroy {
  loading: boolean;
  posts: any;
  postsQuery: QueryRef<any>;
  bfoAllRawdata: any[];

  private querySubscription: Subscription;
  public currentDate: object;
  public errorMessage: boolean = false;
  public errorData: any = [];
  public errorMsg: any;
  public errorCode: any;
  public errorCod: any;
  public errorType: any;
  public currenDate: any;
  public userMsg: any;
  public errorName: any;
  public errorUrl: any;
  public errorhttpCod: any;
  public errorDevMsg: any;
  public isBfoFailed: boolean = false;
  public errorOverview = new Subject<any>();
  public bfoError: any;

  constructor(
    private apollo: Apollo,
    private service: DynamicComponentCreateService,
    private gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure,
    public http: HttpClient, private gcsGraphQlsetbBfoLogsServiceSecure: GcsGraphQlsetbBfoLogsServiceSecure) { }


  public getOverViewInfo(userCode: number, bfoContactId: string, accessToken: string, bfoAccountId: string, levelCode: number, userGroup: number, isAILSet: boolean): any {
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    const screenId = screenIdsForTelemetry['home-overview'].screenId;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    this.postsQuery = this.apollo.watchQuery<any>({
      query: gql`
          query {
            getCasesOverviewStatistics(
              input: { 
                bFOContactID:"${sessionToken}",
                bFOAccountID:"${sessionToken}",
                userGroup:${userGroup},
                level_code:${levelCode},
                JWT: "${sessionToken}",
                screenId: "${screenId}",
                ipAddress: "${ip}" ,
                isAILSet:${isAILSet},
              }
            ) {
              myOpenCase
              newCase
              resolved
              hot
              inProgress
              inProgress
              isBfoFailed
            }
          }
        `
    });

    this.querySubscription = this.postsQuery.valueChanges.subscribe(
      ({ data, loading }) => {
        const getData = data;
        let args = { userGroup, levelCode, bfoContactId, isAILSet }
        this.getBFOStatisticOverviewCount(args).then(value => {
          const counts = [value, getData.getCasesOverviewStatistics];
          const mergeCounts = data => {
            const result = {};
            data.forEach(count => {
              for (let [key, value] of Object.entries(count)) {
                if (result[key]) {
                  result[key] += value;
                } else {
                  result[key] = value;
                }
              }
            });
            return result;
          };
          const mergedObject = mergeCounts(counts);
          sessionStorage.setItem(SessionVariables.overViewInfo, JSON.stringify(mergedObject));
          this.service.overView.next(true);
          this.loading = loading;
        }).catch(err => {
          sessionStorage.setItem(SessionVariables.overViewInfo, JSON.stringify(getData));
          this.service.overView.next(true);
          this.loading = loading;
        });
      },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.getOverViewInfo(userCode, bfoContactId, accessToken, bfoAccountId, levelCode, userGroup, isAILSet)
              }
            })
        }
      }
    );
  }

  // Below are the RESTfull API's for BFO cases
  async getBfoDataCaseOverview(): Promise<any> {
    const getIds = this.service.getIds();
    const baseUrl = environment.bfoCaselistEndPoint;
    let format = 'List'
    let pageSize = 3000
    let pageNumber = 1
    let statusFilter = 14
    let sortedBy = 11
    let sortedOrder = 'asc'
    let communitySource = 'PA'

    const action = `partner-case/cases?format=${format}&page-size=${pageSize}&page-number=${pageNumber}&status-filter=${statusFilter}&sorted-by=${sortedBy}&sorted-order=${sortedOrder}&community-source=${communitySource}`;
    const accessWithOptions = {
      headers: new HttpHeaders({
        'X-IDMS-Authorization': `${getIds.accessToken}`,
        Authorization: `Bearer ${getIds.apigeeAccessToken}`,
        'Content-Type': 'application/json'
      }),
    };
    let url = baseUrl + action;
    const data = await this.caseOverviewData(url, accessWithOptions);
    this.bfoAllRawdata = data || [];
    return this.bfoAllRawdata = data || [];
  }

  async getBFOStatisticOverviewCount(args) {
    return new Promise(async (resolve, reject) => {
      try {
        const BfoResultCount = {
          myOpenCase: 0,
          newCase: 0,
          resolved: 0,
          hot: 0,
          inProgress: 0,
          isBfoFailed: 0
        }
        let { userGroup, levelCode, bfoContactId, isAILSet } = args
        // limited customer will not get the BFO case count(restriction as per business requirement)
        if (userGroup == 1 && levelCode == 4) {
          resolve(BfoResultCount)
        }
        else {
          let BfoRecords
          if (isAILSet || isAILSet == undefined) {
            BfoRecords = await this.getBfoDataCaseOverview()
          } else {
            BfoResultCount.isBfoFailed = 1
            resolve(BfoResultCount)
          }
          if (BfoRecords == 'FAIL') {
            BfoResultCount.isBfoFailed = 1
          } else {
            let allCasesListData = await BfoRecords.cases
            if (allCasesListData) {
              let CFID = this.service.getIds().CFID.replace(/^0+/, '');
              allCasesListData.filter(async function (obj) {
                // filtering on the basis of where user is contact on the case 
                if (obj.contactId == bfoContactId || obj.paContactCfid == CFID) {
                  let { customerStatus, paCaseType, paHotCase } = obj
                  //getting short name for case status
                  paCaseType = paCaseType == "Technical Support (TEC)" ? "TEC" : (paCaseType == "Product Enhancement Request (PER)" ? "PER" : "RWS")

                  if (customerStatus != "Closed" && customerStatus != "Cancelled" && customerStatus != "ESC2TEC" && customerStatus != "SPAM"
                    && !(paCaseType == 'PER' && customerStatus.toUpperCase() == 'BACKLOG')//chris PER & BACKLOG will be closed case
                  ) {
                    BfoResultCount.myOpenCase++
                  }
                  if (customerStatus == "New") {
                    BfoResultCount.newCase++
                  }
                  if (customerStatus == "Resolved") {
                    BfoResultCount.resolved++
                  }
                  if (paHotCase == "Yes") {
                    BfoResultCount.hot++
                  }
                  if (customerStatus == "Assigned" || customerStatus == "Info" || customerStatus == "Pending") {
                    BfoResultCount.inProgress++
                  }
                }
              })
            }
          }
          resolve(BfoResultCount)
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  async caseOverviewData(url, accessWithOptions): Promise<any> {
    return this.http.get(url, accessWithOptions).toPromise()
      .catch(err => {
        this.errorOverview.next({ bfoError: err });
      })
      ;
  }

  public refresh(): void {
    this.postsQuery.refetch();
  }

  ngOnDestroy(): void {
    this.querySubscription.unsubscribe();
  }

}
