import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionVariables,GCSPages } from '../gcs.services/constants';
import {environment} from "../../environments/environment"
import { WindowService } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }
  logout():void{
    sessionStorage.clear();
    sessionStorage.setItem(SessionVariables.isLoggedIn, '0');
    window.location.href=environment.logOutUrl;
    
  }
  ngOnInit(): void {
      this.activatedRoute.params.subscribe((param) => {
       let pageId = param.pageId; 
       if(GCSPages[pageId]){
        this.activatedRoute.queryParams.subscribe((paramsObj) => {
          debugger;
          if(GCSPages[pageId].name=='logout')
          {
            this.logout();
            return;
          }

          if(Object.keys(paramsObj).length === 0){
            this.router.navigate([GCSPages[pageId].route]);
          }else{
            this.router.navigate([GCSPages[pageId].route] , {
              queryParams : paramsObj
            });
          }
        })
       }else{
         //this is to navigate to default route in case if pageId is incorrect
        this.router.navigate([GCSPages['0'].route]);
       }
    })
  }

}
