import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, ErrorCodes } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { environment } from '../../../../environments/environment';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';

@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlChangeEmailServiceSecure {
  public changeEmail: string;
  constructor(private apollo: Apollo, private service: DynamicComponentCreateService, private gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure) { }

  public setChangeEmail(userCode: number): any {
    let gcsChangeEmailquery: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    this.apollo.mutate({
      mutation: gql`
      mutation MyMutation

      { setChangeEmailLogSecure(input: {
        JWT: "${sessionToken}",
        screenId: "${screenId}",    
        ipAddress: "${ip}"
      })
      }
      `,
    })
      .subscribe((result) => {
        sessionStorage.setItem(SessionVariables.changeEmail, JSON.stringify(result));
        this.changeEmail = environment.changeEmail;
        window.location.href = this.changeEmail;
      },
        (err) => {
          const errorMessage = err.message;
          if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
            //code to refresh token and to call again
            this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
              (result) => {
                const data = result.data.renewToken;
                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                  // probably should logout the user
                } else if (data.JWT) {
                  sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                  const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                  updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                  updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                  updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                  updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                  sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                  this.setChangeEmail(userCode);
                }
              })
          }
        });
  }

}
