import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GcsGraphQlSessionIdServiceSecure {
  constructor(
    private apollo: Apollo,
    private service: DynamicComponentCreateService,
    private http: HttpClient
  ) { }

  public getSessionId(): Observable<any> {
    const userSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    const refreshToken = userSessionData.data.getLoggedInUserInfo.JWTRefreshToken;
    const idmsRefreshAccessToken = userSessionData.data.getLoggedInUserInfo.idmsRefreshAccessToken;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    let renewTokenRequest: QueryRef<any>;
    renewTokenRequest = this.apollo.watchQuery({
      query: gql`
        query {
          renewToken(input: {
            JWT: "${sessionToken}",
            refreshToken: "${refreshToken}",
            ipAddress: "${ip}",
            idmsRefreshAccessToken: "${idmsRefreshAccessToken}"
          }) {
            JWT
            refreshToken
            accessToken
            apigeeAccessToken
            message
          }
        }
        `,
    });
    return renewTokenRequest.valueChanges
  }
}
