
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { DynamicComponentCreateService } from './gcs.business.services/dynamic-component-create.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CustomerRegistationService {


  constructor(private apollo: Apollo,
    public service: DynamicComponentCreateService,
    public router: Router,
    private httpclient: HttpClient) { }

  public customerEmailstatusReceived = new BehaviorSubject(-1);


  public getBlockedEmailDomains():any
  {
    debugger;
       
      try {
        let blockedEmailDomains: QueryRef<any>;
        blockedEmailDomains = this.apollo.watchQuery({
          query: gql`query{
                  getBlockedEmailDomains
              } `,
        });
       
        blockedEmailDomains.valueChanges.subscribe((result)=>{
        
          this.service.blockedEmailDomains.next(result.data.getBlockedEmailDomains)
        });
        
      } catch (error) {
        debugger;
        console.error(error);
        
      }


  }


}
