import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, ApolloClient, ApolloLink, createHttpLink, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { environment } from '../environments/environment';
import { createAuthLink } from 'aws-appsync-auth-link';

const uri = environment.endPoint; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  const authLink = createAuthLink({
    url: environment.endPoint,
    region: environment.region,
    auth: {
      type: 'API_KEY',
      apiKey: atob(environment.samir),
    }
  });

  return {
    link: ApolloLink.from([authLink, httpLink.create({uri})]),
    cache: new InMemoryCache(),
  };
  // return {
  //   link: httpLink.create({uri}),
  //   cache: new InMemoryCache(),
  // };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
