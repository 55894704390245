import { Injectable } from '@angular/core';
import { SessionVariables } from '../constants'
const internalIp = require('internal-ip');

@Injectable({
  providedIn: 'root'
})
export class GcsIpService {

  constructor() { }

  getIp(): void {
    (async () => {
      sessionStorage.setItem(SessionVariables.ip, await internalIp.v4())
    })();
  }

}
