import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables } from '../constants';
import { DynamicComponentCreateService } from '../gcs.business.services/dynamic-component-create.service';
@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlIdmsInfoService {

  constructor(private apollo: Apollo, public service: DynamicComponentCreateService) { }

  public getIdmsInfo(): any {
    let idmsQuery: QueryRef<any>;
    idmsQuery = this.apollo.watchQuery({
      query: gql`
      query idmsQuery {
        getIDMSDetails{
          CFID
          companyName
          userName
          companyName
          userEntitlement
        }
      }
      `,
    });

    idmsQuery.valueChanges.subscribe(result => {
      sessionStorage.setItem(SessionVariables.IdmsInfo, JSON.stringify(result.data.getIDMSDetails));
      this.service.companyOverview.next(true);
    });
    return JSON.parse(sessionStorage.getItem(SessionVariables.IdmsInfo));
  }

}
