import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables } from '../constants';
import { DynamicComponentCreateService } from '../gcs.business.services/dynamic-component-create.service';

@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlRearrangeViewService {

  private getRearrangeViewQuery: QueryRef<any>;
  private getHeaderSubmenuQuery: QueryRef<any>;
  public userLoginQuery: QueryRef<any>;

  constructor(private apollo: Apollo, private service: DynamicComponentCreateService) { }

  // this is for get rearrange view query if have Fradrated ID
  public getRearrangeViewFun(fadratedId): void {
    const loggedInUserDetails = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails)).data.getLoggedInUserInfo;
    const userCode = loggedInUserDetails.user_code;
    this.getRearrangeViewQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getRearrangeView(id: "${userCode}"){
          id
          services{
            servicename
            show
            order
          }
        }
      }`,
    });
    this.getRearrangeViewQuery.valueChanges.subscribe((result) => {
      if (result && result.data && result.data.getRearrangeView !== null) {
        const data = result.data || {};
        const getOrderList = (data.getRearrangeView && data.getRearrangeView.services) || [];
        sessionStorage.setItem(SessionVariables.rerrangeViewData, JSON.stringify(getOrderList));
        const defaultFlag = false;
        sessionStorage.setItem(SessionVariables.defaultFlag, JSON.stringify(defaultFlag));
        this.service.reArrangeViewData.next(true);
      } else {
        const menuId = this.service.getIds('menuId');
        this.getHeaderMenuList(menuId);
      }
    });
  }

  // get menu list with submenu
  public getHeaderMenuList(menuId): void {
    this.getHeaderSubmenuQuery = this.apollo.watchQuery({
      query: gql`
        query MyQuery {
          getHeaderLink(id: "${menuId}") {
            menuList {
              chipName
              subMenu
            }
          }
        }`,
    });
    this.getHeaderSubmenuQuery.valueChanges.subscribe((result) => {
      if (result) {
        const data = result.data || {};
        const getMenuList = (data.getHeaderLink && data.getHeaderLink.menuList) || [];
        const makeOrderList = [];
        getMenuList.forEach(menu => {
          if (menu.chipName === 'HOME') {
            let order = 1;
            menu.subMenu.forEach(subMenu => {
              const obj: any = {};
              obj.servicename = subMenu;
              obj.show = true;
              obj.order = order;
              makeOrderList.push(obj);
              order++;
            });
          }
        });
        sessionStorage.setItem(SessionVariables.rerrangeViewData, JSON.stringify(makeOrderList));
        const defaultFlag = true;
        sessionStorage.setItem(SessionVariables.defaultFlag, JSON.stringify(defaultFlag));
        this.service.reArrangeViewData.next(true);
      }
    });
  }

}
