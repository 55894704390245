import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, ErrorCodes } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';
@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlSystemAssetViewerServiceSecure {

  constructor(
    private apollo: Apollo,
    private service: DynamicComponentCreateService,
    private gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure) { }

  public getSAV():void {
    
        let SAVQuery: QueryRef<any>;
        let ip = sessionStorage.getItem(SessionVariables.ip);
        ip = ip ? ip : '';
        const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
        let screenId = '';
        let siteId = '';

        SAVQuery = this.apollo.watchQuery({
        query: gql`query{
            getSystemAssetViewer(input:{
            siteId:"${siteId}",
            JWT: "${sessionToken}",
            screenId: "${screenId}",
            ipAddress: "${ip}"
            }){
              savName
              savType
              savDescription
              savVersion
              savVersionLink
              savSize
              savDate
              savDocumentationDesc
              savDocumentationLink
              savGroupName
              savGroupDescription
              savID
              savGroupID
            }
        }
        `,
        });
        

        SAVQuery.valueChanges.subscribe((result) => {
        const resultData = result && result.data;
        const systemAssetViewerDetails = resultData && resultData.getSystemAssetViewer;
        sessionStorage.setItem(SessionVariables.savDetails, JSON.stringify(systemAssetViewerDetails));
        this.service.getSAV.next(true);
        
        },
        (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
            //code to refresh token and to call again
            this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
                const data = result.data.renewToken;
                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
                } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.getSAV();
                }
            })
        }
        });
  }

}
