import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionVariables } from '../../gcs.services/constants';
import {environment} from "../../../environments/environment"
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {}

  public isLogin: any;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     // debugger;
      this.isLogin = sessionStorage.getItem(SessionVariables.isUserLoggedIn) === '1';
      if (this.isLogin){
        return true;
      }else{
        // this.router.navigate(['/home'], { queryParams: { returnUrl: state.url }});
        // window.location.href=environment.loginUrl+`&state=${window.location.origin}${state.url}`;
        const url =environment.loginUrl+`&state=${state.url}`;
        // console.log("state "+state)
        // console.log("state "+state.url)
        window.location.href=url;

        return false;
      }
  }
}
