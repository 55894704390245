import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, ErrorCodes } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure'

@Injectable({
    providedIn: 'root'
})

export class GcsGraphQlRearrangeViewServiceSecure {

    private getRearrangeViewQuery: QueryRef<any>;
    private getHeaderSubmenuQuery: QueryRef<any>;
    public userLoginQuery: QueryRef<any>;

    constructor(private apollo: Apollo, private service: DynamicComponentCreateService, private gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure) { }

    // to update the rearrange view if have Fedrated ID "704a1000-7517-4d8a-ba89-0bb5538ad86c"
    public updateRearrangeView(UpdateRearrangeViewInput): void {
        const loggedInUserDetails = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails)).data.getLoggedInUserInfo;
        const userCode = loggedInUserDetails.user_code;
        const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
        let ip = sessionStorage.getItem(SessionVariables.ip);
        ip = ip ? ip : '';
        let screenId = '';
        this.apollo.mutate({
            mutation: gql`
            mutation{
                setRearrangeViewSecure(input:{
                    JWT: "${sessionToken}",
                    screenId: "${screenId}",
                    ipAddress: "${ip}",
                    services:${UpdateRearrangeViewInput}
                })
              }`,
        })
            .subscribe(data => {

            },
                (err) => {
                    const errorMessage = err.message;
                    if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
                        //code to refresh token and to call again
                        this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
                            (result) => {
                                const data = result.data.renewToken;
                                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                                    // probably should logout the user
                                } else if (data.JWT) {
                                    sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                                    const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                                    updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                                    updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                                    updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                                    updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                                    sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                                    this.updateRearrangeView(UpdateRearrangeViewInput);
                                }
                            })
                    }
                }
            );
    }

    // to update the rearrange view if not have Fedrated ID
    public setRearrangeViewDefault(UpdateRearrangeViewInput): void {
        const loggedInUserDetails = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails)).data.getLoggedInUserInfo;
        const userCode = loggedInUserDetails.user_code;
        const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
        let ip = sessionStorage.getItem(SessionVariables.ip);
        ip = ip ? ip : '';
        let screenId = '';
        this.apollo.mutate({
            mutation: gql`mutation{
                setRearrangeViewSecure(input:{
                          JWT: "${sessionToken}",
                          screenId: "${screenId}",
                          ipAddress: "${ip}",
                  services:[
                  {
                    order: 1,
                    servicename: "Overview",
                    show: true
                  },
                  {
                    order: 2,
                    servicename: "Case List",
                    show: true
                  },
                  {
                    order: 3,
                    servicename: "Company Overview",
                    show: true
                  },
                  {
                    order: 4,
                    servicename: "Notifications & Alerts",
                    show: true
                  }]
                })
              }`,
        })
            .subscribe(data => {

            },
                (err) => {
                    const errorMessage = err.message;
                    if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
                        //code to refresh token and to call again
                        this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
                            (result) => {
                                const data = result.data.renewToken;
                                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                                    // probably should logout the user
                                } else if (data.JWT) {
                                    sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                                    const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                                    updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                                    updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                                    updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                                    updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                                    sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                                    this.setRearrangeViewDefault(UpdateRearrangeViewInput);
                                }
                            })
                    }
                }

            );
    }

    // this is for get rearrange view query if have Fradrated ID
    public getRearrangeViewFun(fadratedId): void {
        const loggedInUserDetails = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails)).data.getLoggedInUserInfo;
        const userCode = loggedInUserDetails.user_code;
        const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
        const userGroup = loggedInUserDetails.userGroup;
        const levelCode = loggedInUserDetails.level_code;
        let ip = sessionStorage.getItem(SessionVariables.ip);
        ip = ip ? ip : '';
        let screenId = '';
        this.getRearrangeViewQuery = this.apollo.watchQuery({
            query: gql`
            query{
                getRearrangeViewSecure(input:{
                 JWT: "${sessionToken}",
                       screenId: "${screenId}",
                       ipAddress: "${ip}",
                       userGroup:${userGroup},
                       level_code:${levelCode}
                }){
                  services{
                    servicename
                    show
                    order
                  }
                }
              }`,
        });
        this.getRearrangeViewQuery.valueChanges.subscribe((result) => {
            if (result && result.data && result.data.getRearrangeViewSecure !== null) {
                const data = result.data || {};
                const getOrderList = (data.getRearrangeViewSecure && data.getRearrangeViewSecure.services) || [];
                sessionStorage.setItem(SessionVariables.rerrangeViewData, JSON.stringify(getOrderList));
                const defaultFlag = false;
                sessionStorage.setItem(SessionVariables.defaultFlag, JSON.stringify(defaultFlag));
                this.service.reArrangeViewData.next(true);
            } else {
                const menuId = this.service.getIds('menuId');
                this.getHeaderMenuList(menuId);
            }
        },
            (err) => {
                const errorMessage = err.message;
                if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
                    //code to refresh token and to call again
                    this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
                        (result) => {
                            const data = result.data.renewToken;
                            if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                                // probably should logout the user
                            } else if (data.JWT) {
                                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                                this.getRearrangeViewFun(fadratedId);
                            }
                        })
                }
            }
        );
    }

    // get menu list with submenu
    public getHeaderMenuList(menuId): void {
        this.getHeaderSubmenuQuery = this.apollo.watchQuery({
            query: gql`
        query MyQuery {
          getHeaderLink(id: "${menuId}") {
            menuList {
              chipName
              subMenu
            }
          }
        }`,
        });
        this.getHeaderSubmenuQuery.valueChanges.subscribe((result) => {
            if (result) {
                const data = result.data || {};
                const getMenuList = (data.getHeaderLink && data.getHeaderLink.menuList) || [];
                const makeOrderList = [];
                getMenuList.forEach(menu => {
                    if (menu.chipName === 'HOME') {
                        let order = 1;
                        menu.subMenu.forEach(subMenu => {
                            const obj: any = {};
                            obj.servicename = subMenu;
                            obj.show = true;
                            obj.order = order;
                            makeOrderList.push(obj);
                            order++;
                        });
                    }
                });
                // makeOrderList = makeOrderList.filter(ele => ele.servicename != 'Overview')
                sessionStorage.setItem(SessionVariables.rerrangeViewData, JSON.stringify(makeOrderList));
                const defaultFlag = true;
                sessionStorage.setItem(SessionVariables.defaultFlag, JSON.stringify(defaultFlag));
                this.service.reArrangeViewData.next(true);
            }
        });
    }

}
