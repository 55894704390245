<html>

<head>
  <script src="../assets/js/ag_grid3.js"></script>
  <link rel="stylesheet" href="https://unpkg.com/@progress/kendo-theme-default@4.42.0/dist/all.css" />
</head>

<body class="se-font-technical body">
  <div fxFlex="100" fxLayout="column" fxLayoutGap="10px" class="headerParentDiv">
    <router-outlet></router-outlet>
  </div>
  <!-- cookies banner -->
  <div class="cookie-banner container-fluid" *ngIf="showCookieBanner">
    <div class="row">
      <div class="col-xs-12 col-sm-10">
        <p class="cookie-banner-content">Our website uses cookies to deliver the best experience. By using our site, you
          agree to our <a href="{{privacyPolicyUrl}}">Privacy Policy</a>.
          You can adjust your Cookies Settings <a class="privacy-policy-here"
            (click)="openCookieSettingsPopup()">here</a>.
        </p>
      </div>
      <div class="col-xs-12 col-sm-2">
        <se-button (click)="acceptCookiePolicy()" option="flat" color="secondary" padding="none" size="nano"
          block="true">Accept All</se-button>
      </div>
    </div>

  </div>
  <!-- cookie settings dialog -->
  <kendo-dialog id="cookie-settings-centre-popup" title="Cookies Settings" *ngIf="isCookieSettingsDialogOpen"
    (close)="close('cancel')" [minWidth]="250" [width]="450">
    <div class="row">
      <div class="col-sm-6">
        <div class="row necessary-cookies-subtitle">Necessary cookies</div>
        <div id="necessary-cookie" class="cookie-dialog-content">Necessary cookies are required for our website to work
          properly and can’t be turned
          off. They are sent to your computer or device when you request a specific action or service, e.g. when you log
          in,
          fill out a form, or set your cookie preferences. If you set your browser to block or alert you about these
          cookies, certain parts of our website won’t work.
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row necessary-cookies-subtitle">
          <span id="statistical-cookie" class="col-md-8 col-xs-9 padding0">Statistical cookies</span>
          <label class="col-md-4 col-xs-3 switch">
            <input type="checkbox" [checked]="statsCookie" (change)="checkStatus()">
            <span class="slider round"></span>
          </label>
        </div>
        <div class="row cookie-dialog-content stats-cookies">Thanks to statistical or analytical cookies, delivered by
          us and other companies, we
          can count the visits on our website and know the sources of traffic. The data we derive helps us understand
          what visitors like
          most and improve the website. If you don’t accept them, we won’t be able to improve your experience based on
          data from your visit.
        </div>
      </div>
    </div>
    <hr class="separation-line">
    <div><button type="button" class="btn btn-stats" (click)="acceptCookiePolicy()">Save</button></div>
  </kendo-dialog>
  <!---Follwoing div for error while timeout execution-->
  <div id="errorPopup">
    <div kendoDialogContainer></div>
  </div>
  <button kendoButton *ngIf="!showLogoutWindow" (click)="openLogoutWindow()" hidden>Open dialog</button>
  <kendo-dialog
    *ngIf="showLogoutWindow"
    [minWidth]="250"
    [width]="525"
  >
    <div class="logout-popup">
      <h4 >Your session is about to expire!</h4>
      <p *ngIf="timerMinutes>1" class="padding10px">
        The system will log you out soon and end this session. <br> Logging out in <b>{{timerMinutes}} minutes and {{timerSeconds}} seconds</b>.
      </p>
      <p *ngIf="timerMinutes==1" class="padding10px">
        The system will log you out soon and end this session. <br> Logging out in <b>{{timerMinutes}} minute and {{timerSeconds}} seconds</b>.
      </p>
      <p *ngIf="timerMinutes<=0" class="padding10px">
        The system will log you out soon and end this session. <br> Logging out in <b>{{timeLeft}} seconds</b>.
      </p>
      <button kendoButton [disabled]="disableKeepMeLoggedIn" (click)="closeLogoutWindow('no')" class="stay-logged-in-btn">Keep me logged in</button>&nbsp;
      <button kendoButton (click)="closeLogoutWindow('yes')" class="logout-btn" >Logout</button>
    </div>
  </kendo-dialog>
</body>

</html>
