import { Injectable } from '@angular/core';
// import { environment } from './../../../environments/environment';
// import {
//   AssumeRoleCommand,
//   AssumeRoleWithSAMLCommand,

//   STSClient
// } from "@aws-sdk/client-sts";
// import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
// import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";

import { CloudWatchLogs } from '@aws-sdk/client-cloudwatch-logs'
import { AwsStsGetTempCredentialsService } from './aws-sts-get-temp-credentials.service';

@Injectable({
  providedIn: 'root'
})
export class GcsCloudwatchService {
  private logGroupName = 'GCSWebCustomLog'
  private REGION = "us-east-1";







  private CWclient :any;
  //private CWclient = new CloudWatchLogs({ region: this.REGION })

  constructor(private awssts: AwsStsGetTempCredentialsService) { }

  private async createlogStream(logGroupName, usercode) {

    let tf = this.formatDateTimeStreamName();
    // console.log(tf);
    var logStreamName = `${tf}/${usercode}`




    return new Promise(async (resolve, reject) => {
      const result = await this.getuploadSequenceToken(logGroupName, logStreamName)
      if (result && result.Token)
        resolve(result);

      else

        this.CWclient.createLogStream({
          "logGroupName": logGroupName,
          "logStreamName": logStreamName
        }, (err, data) => {
          if (err) reject(err);

          resolve({ Token: undefined, streamName: logStreamName });

        })
    })
  }


  private async getuploadSequenceToken(logGroupName, logStreamName): Promise<{ Token: string, streamName: string }> {
    return new Promise(async (resolve, reject) => {
      // console.log("this.CWclient.config.credentials",this.CWclient.config.credentials())
      await this.CWclient.describeLogStreams({
        "logGroupName": logGroupName,
        "logStreamNamePrefix": logStreamName,
      }, (err, data) => {
        if (err) {
          console.log(err);
          reject(err);
        }

        // console.log(" logs:DescribeLogStreams",data)

        if (data && data.logStreams) {
          var uploadSequenceToken = data.logStreams.length > 0 ? data.logStreams[0].uploadSequenceToken : undefined

          var res = { Token: uploadSequenceToken, streamName: logStreamName };

          resolve(res)

        }
        else
          reject(new Error('data is empty'))

      })

    })



  }


  private async sendLog(msg, params) {
    try {

      const input = {
        "logEvents": [
          {
            "message": msg,
            "timestamp": Date.now()
          }
        ],
        "logGroupName": this.logGroupName,
        "logStreamName": params.streamName,
        "sequenceToken": params.Token
      }

      const res = this.CWclient.putLogEvents(input, (err, data) => {
        if (err)
          console.error(err);
      })

      //console.log(input)


    } catch (error) {
      console.log(error)

    }


  }


  public async writeLog(msg, usercode, tempCred, groupName = this.logGroupName) {
    //debugger
    try {
      //console.log("tempCred", tempCred)
      // this.CWclient = new CloudWatchLogs({ region: this.REGION, 
      //   credentials:{ accessKeyId: "ASIA3K73544MWTDIHY6F",
      //   secretAccessKey: "mOQys/iGDv0w/fCClMkL+QR30Y/pIJovI0UlO3Dl",
      //   sessionToken:"FwoGZXIvYXdzEP3//////////wEaDJik2MwQVE1l1eXkVCKxASKyq/qDJTDA2NqK97TIwuAsQRE+2TXu7odzeVcQyNuQCHjGOF1Mp6oZCSiqK0hARDJ9HGDE4wm8dEt4eUSbbnEZwYfcLN/54u4UYIbgbTXHhYrGsQQde3oUoDJbNpGXGqDfW1DVl9Ph6utOWag3PRkWs+vTRfLxYylWT64lKlDzDzx3nntVXmP62UhEUY/Ef5lYSd2a6wrV+wqgE8QpxKFuHzO+hSw8YhkHgAI/Dx3qcyjc99mlBjItnr7t6763vLBreP16ooGguQ7nJwVUIuQRPPkzuYOUOD97jPUjyvG+a2a2hRpb" }});
    
      this.CWclient = new CloudWatchLogs({ region: this.REGION, 
        credentials:{ accessKeyId: tempCred.Access_Key,
        secretAccessKey: tempCred.Secret_Key,
        sessionToken: tempCred.Session_Token }});
    
      //console.log("************************************","tempCred", tempCred.Access_Key ,tempCred.Secret_Key,tempCred.Session_Token )

      this.createlogStream(groupName, usercode)

        // .then(async (logStreamName) => {
        //   const result = await this.getuploadSequenceToken(groupName, logStreamName)
        //   //console.log(result,"//////")
        //   return result

        // })



        .then((params) => {

          //console.log(params, "********")
          this.sendLog(msg, params);
        }).catch((err => {
          console.error(err)
        }))

    } catch (error) {
      console.log(error)

    }
  }




  private createUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


  private formatDateTimeStreamName() {
    let curDate = new Date();
    let day = curDate.getUTCDate().toString().padStart(2, '0');
    let month = (curDate.getMonth() + 1).toString().padStart(2, '0');
    let year = curDate.getFullYear()
    // let hour = curDate.getHours().toString().padStart(2, '0');
    // let min = curDate.getMinutes().toString().padStart(2, '0');
    let formatedDate = `${year}-${month}-${day}`  //`${year}-${month}-${day} ${hour}-${min}` 
    return formatedDate;
  }





}

