import { Observable } from 'rxjs';
import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, screenIdsForTelemetry, ErrorCodes } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';


@Injectable({
  providedIn: 'root'
})
export class GcsGraphQlProfileInformationServiceSecure {

  constructor(private apollo: Apollo, private service: DynamicComponentCreateService,
    private gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure) { }

  public getProfileInformation(idmsFederatedId: string): Observable<any> {
    let userLoginQuery: QueryRef<any>;
    const id = `${idmsFederatedId}`;
    userLoginQuery = this.apollo.watchQuery({
      query: gql`query MyQuery {
        getProfileInformation(input: {idmsFederatedId: "${id}"}) {
          AD_Domain
          CompanyCategory
          Country_Desc
          JWT
          Region_Code
          Region_Desc
          SAPLinked
          State_Code
          State_Name
          UAL_GUID
          UserGroup
          bFOAccountID
          bFOContactID
          company
          company_code
          country_code
          industryLowLevel
          level_code
          sAMAccountName
          sap_contact_ID
          sap_employee_id
          user_MailStop
          user_address
          user_city
          user_cn
          user_code
          user_companydivision
          user_companydivision_OLD
          user_country
          user_email
          user_epush
          user_fax
          user_jobtitle
          user_lastvisiteddate
          user_name
          user_phone
          user_phone_ext
          user_postalcode
          user_state
          user_surname
        }
      }
       `,
    });

    return userLoginQuery.valueChanges;
  }

  public updateProfileInformation(formData: any, stateNameShort: string, selectedCountryISOcode: string): any {
    let formEditableData = formData.value;
    let loggedInUserDetails = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails)).data.getLoggedInUserInfo;
    if (!stateNameShort) {
      stateNameShort = '';
      formEditableData.state = null;
    }
    const address = this.service.escape (formEditableData.address.trim());
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = screenIdsForTelemetry['profile-account-information'].screenId;

    this.apollo.mutate({
      mutation: gql`
      mutation MyMutation {
        setUserProfileDetails(
          input:{
            email:"${formData.controls.businessEmail.value}"
            address: """${address}""",
            city: "${formEditableData.city}",
            countryCode: ${formEditableData.location},
            countryISOCode: "${selectedCountryISOcode}",
            firstName: "${formEditableData.firstName}",
            languageCode: "${loggedInUserDetails.languageCode}",
            lastName: "${formEditableData.lastName}",
            profileLastUpdateSource: "GCSWeb",
            stateCode: ${formEditableData.state},
            stateNameShort: "${stateNameShort}",
            telephone: "${formEditableData.telephone}",
            zipCode: "${formEditableData.zipcode}",
            JWT: "${sessionToken}",
            screenId: "${screenId}",
            ipAddress: "${ip}"
          }
        )
        { GCSDbLogResponse GCSDbResponse IDMSResponse }
      }
    `,
    }).subscribe(data => {
      this.service.updateProfileInfo.next(true);
    },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          // code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.updateProfileInformation(formData, stateNameShort, selectedCountryISOcode);
              }
            });
        }
        this.service.profileEditFormSubmit.next(false);
      });
  }

}
