import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, ErrorCodes } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';

@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlsetbBfoLogsServiceSecure {
  public bfoLogs: string;
  constructor(private apollo: Apollo, private service: DynamicComponentCreateService, private gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure, private dynamicComponentService: DynamicComponentCreateService) { }

  public setBfoLog(userCode: number, LogText: string, SourceApp: string, API_HTTP_Code: string, API_Error_Code: string,
    API_URL: string, API_Error_Type: string, API_UserMessage: string, API_DevMessage: string, API_Data: string): any {
      try {
        
        this.dynamicComponentService.WritelogToCloudWatch(JSON.stringify(`{
        RequestID:null,
        LogText:"API Event",
        SourceApp:"GCSWeb2020",
        API_HTTP_Code:"${API_HTTP_Code}",
        API_Error_Code:"${API_Error_Code}",
        API_Error_Type:"${API_Error_Type}",
        API_URL:"${API_URL}",
        API_UserMessage:"${API_UserMessage}",
        API_DevMessage:"${API_DevMessage}",
        API_Data:"${API_Data}"

        }`))
        debugger;
        // console.error(API_DevMessage);
        API_DevMessage=  this.dynamicComponentService.escape(API_DevMessage)
        //API_DevMessage = API_DevMessage.replace(/(\\)\1+/g, '$1').replace(/[\t]/g, '\\t').replace(/[\n]/g, '\\n')
        // console.error(API_DevMessage);
       
     
    let setBfoLogquery: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    this.apollo.mutate({
      mutation: gql`
      mutation 
      { setBfoLogs(input: {
        JWT: "${sessionToken}",
        screenId: "${screenId}",    
        ipAddress: "${ip}",
        RequestID:null,
        LogText:"API Event",
        SourceApp:"GCSWeb2020",
        API_HTTP_Code:"${API_HTTP_Code}",
        API_Error_Code:"${API_Error_Code}",
        API_Error_Type:"${API_Error_Type}",
        API_URL:"${API_URL}",
        API_UserMessage:"${API_UserMessage}",
        API_DevMessage:"${API_DevMessage}",
        API_Data:"${API_Data}",
        API_Flags:null
      })
      }
      `,
    })
      .subscribe((result) => {
        sessionStorage.setItem(SessionVariables.bfoLogs, JSON.stringify(result));
      },
        (err) => {
          const errorMessage = err.message;
          if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
            //code to refresh token and to call again
            this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
              (result) => {
                const data = result.data.renewToken;
                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                  // probably should logout the user
                } else if (data.JWT) {
                  sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                  const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                  updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                  updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                  updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                  updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                  sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                  this.setBfoLog(userCode, LogText, SourceApp, API_HTTP_Code, API_Error_Code,
                    API_URL, API_Error_Type, API_UserMessage, API_DevMessage, API_Data);
                }
              })
          }
        });
      } catch (error) {
        console.error(error)
      }
  }

}
