import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, ErrorCodes } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';
@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlCreateNewSiteServiceSecure {

  constructor(
    private apollo: Apollo,
    private service: DynamicComponentCreateService,
    private gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure) { }

  public getIndustryTypes(): void {
    let industryTypesQuery: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    industryTypesQuery = this.apollo.watchQuery({
      query: gql`query{
        getIndustryTypesForCreateSite(input:{
          JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}"
        }){
          industryTypes {
            IndustryTypeCode
            IndustryTypeDesc
            SortOrder
            Enabled
          }
         }
       }
      `,
    });

    industryTypesQuery.valueChanges.subscribe((result) => {
      const resultData = result && result.data;
      const industryTypes = resultData && resultData.getIndustryTypesForCreateSite;
      sessionStorage.setItem(SessionVariables.industryTypes, JSON.stringify(industryTypes));
      this.service.getIndustryTypes.next(true);
      },
      (err) => {
      const errorMessage = err.message;
      if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
        //code to refresh token and to call again
        this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
          (result) => {
            const data = result.data.renewToken;
            if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
              // probably should logout the user
            } else if (data.JWT) {
              sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
              const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
              updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
              updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
              updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
              updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
              sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
              this.getIndustryTypes();
            }
          })
      }
    });
  }

  public async getProductVersions(productFamily: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let productVersionsQuery: QueryRef<any>;
      let ip = sessionStorage.getItem(SessionVariables.ip);
      ip = ip ? ip : '';
      const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
      let screenId = '';
      productVersionsQuery = this.apollo.watchQuery({
        query: gql`query{
          getProductVersionsForCreateSite(input:{
            JWT: "${sessionToken}",
            screenId: "${screenId}",
            ipAddress: "${ip}",
            productFamily: "${productFamily}"
          }){
            productVersions {
              Version_Code
              Version_Name
              Version_Desc
              Version_Status
            }
          }
        }
        `,
      });
      productVersionsQuery.valueChanges.subscribe((result) => {
        sessionStorage.setItem(SessionVariables.productVersions, JSON.stringify(result.data.getProductVersionsForCreateSite.productVersions));
        resolve(result.data.getProductVersionsForCreateSite.productVersions);
      },
        (err) => {
          const errorMessage = err.message;
          if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
            //code to refresh token and to call again
            this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
              (result) => {
                const data = result.data.renewToken;
                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                  // probably should logout the user
                } else if (data.JWT) {
                  sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                  const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                  updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                  updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                  updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                  updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                  sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                  this.getProductVersions(productFamily);
                }
              });
          }
        });
    });
  }

  public async getAppType(productFamily: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let appTypeQuery: QueryRef<any>;
      let ip = sessionStorage.getItem(SessionVariables.ip);
      ip = ip ? ip : '';
      const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
      let screenId = '';
      appTypeQuery = this.apollo.watchQuery({
        query: gql`query{
          getAppTypeForCreateSite(input:{
            JWT: "${sessionToken}",
            screenId: "${screenId}",
            ipAddress: "${ip}",
            productFamily: "${productFamily}"
          }){
            appTypes {
              ApplicationTypeCode
              ApplicationTypeDesc
            }
          }
        }
        `,
      });
      appTypeQuery.valueChanges.subscribe((result) => {
        sessionStorage.setItem(SessionVariables.appTypes, JSON.stringify(result.data.getAppTypeForCreateSite.appTypes));
        resolve(result.data.getAppTypeForCreateSite.appTypes);
      },
        (err) => {
          const errorMessage = err.message;
          if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
            //code to refresh token and to call again
            this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
              (result) => {
                const data = result.data.renewToken;
                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                  // probably should logout the user
                } else if (data.JWT) {
                  sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                  const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                  updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                  updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                  updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                  updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                  sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                  this.getAppType(productFamily);
                }
              });
          }
        });
    });
  }

  public getDCS(): void {
    let DCSQuery: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    DCSQuery = this.apollo.watchQuery({
      query: gql`query{
        getDCSForCreateSite(input:{
          JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}"
        }){
          DCSList {
            SystemType_Code
            SystemTypeSub_Code
            SystemType_Desc
            enabled
            SortOrder
            Create_Date
            Create_System_User
            Create_user_code
            Modify_Date
            Modify_System_User
            Modify_user_code
          }
         }
       }
      `,
    });

    DCSQuery.valueChanges.subscribe((result) => {
      const resultData = result && result.data;
      const DCSList = resultData && resultData.getDCSForCreateSite;
      sessionStorage.setItem(SessionVariables.DCSList, JSON.stringify(DCSList));
      this.service.getDCS.next(true);
      },
      (err) => {
      const errorMessage = err.message;
      if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
        //code to refresh token and to call again
        this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
          (result) => {
            const data = result.data.renewToken;
            if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
              // probably should logout the user
            } else if (data.JWT) {
              sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
              const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
              updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
              updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
              updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
              updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
              sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
              this.getDCS();
            }
          })
      }
    });
  }

  public async createSite(valueObject: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let ip = sessionStorage.getItem(SessionVariables.ip);
      ip = ip ? ip : '';
      const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
      let screenId = '';
      let Install_date=(valueObject.Install_date == null) ?`${valueObject.Install_date}`:`"${valueObject.Install_date}"`;
      let InstalledDate_TRIC=(valueObject.InstalledDate_TRIC == null) ?`${valueObject.InstalledDate_TRIC}`:`"${valueObject.InstalledDate_TRIC}"`;
      let InstalledDate_PLC=(valueObject.InstalledDate_PLC == null) ?`${valueObject.InstalledDate_PLC}`:`"${valueObject.InstalledDate_PLC}"`;
      this.apollo.mutate({
        mutation: gql`
      mutation {
        createSite(input: {
          JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}",
          Customer_Code: ${valueObject.Customer_Code},
          SystemName: "${valueObject.SystemName}",
          IndustryType_CD: ${valueObject.IndustryType_CD},
          blnIAPT: ${valueObject.blnIAPT},
          blnTRIC: ${valueObject.blnTRIC},
          blnPLC: ${valueObject.blnPLC},
          version_sw: ${valueObject.version_sw},
          Install_date: ${Install_date},
          nodes_no: ${valueObject.nodes_no},
          ApplicationType_CD_IA: ${valueObject.ApplicationType_CD_IA},
          version_TRIC_sw: ${valueObject.version_TRIC_sw},
          Version_SW_PLC: ${valueObject.Version_SW_PLC},
          System_version_Code: ${valueObject.System_version_Code},
          InstalledDate_TRIC: ${InstalledDate_TRIC},
          InstalledDate_PLC: ${InstalledDate_PLC},
          System_Chassis: ${valueObject.System_Chassis},
          PLC_NodeNo: ${valueObject.PLC_NodeNo},
          ApplicationType_CD_TRIC: ${valueObject.ApplicationType_CD_TRIC},
          ApplicationType_CD_PLC: ${valueObject.ApplicationType_CD_PLC},
          System_DCS: ${valueObject.System_DCS},
          System_DCS_PLC: ${valueObject.System_DCS_PLC},
          Create_User_Code: ${valueObject.Create_User_Code}
        }
      )
    }
    `,
      }).subscribe(data => {
        resolve('success');
      },
        (err) => {
          const errorMessage = err.message;
          if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
            //code to refresh token and to call again
            this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
              (result) => {
                const data = result.data.renewToken;
                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                  // probably should logout the user
                } else if (data.JWT) {
                  sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                  const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                  updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                  updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                  updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                  updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                  sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                  this.createSite(valueObject);
                }
              })
          }
          else {
            resolve('fail');
          }
        });
    });
  }


}
