import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import gql from 'graphql-tag';
//import { DynamicComponentCreateService } from './dynamic-component-create.service';

@Injectable({
  providedIn: 'root'
})
export class AwsStsGetTempCredentialsService {
  constructor(private apollo: Apollo
   //, public service: DynamicComponentCreateService
    ) { }


    public async getSTSTempCredentials(): Promise<any> {
      return new Promise(async(resolve,reject) => {
        try {
          debugger
          let tempCredentials: QueryRef<any>;
          tempCredentials = await this.apollo.watchQuery({
            query: gql`
            query {
  
            AssumeRoleForTempCredentials {
              Access_Key
              Secret_Key
              Session_Token
              Expiration
              body
              statusCode
            }
            }
          `,
          });
          tempCredentials.valueChanges.subscribe((result) => {
            console.log('getSTSTempCredentials OK' )
            //console.log('getSTSTempCredentials',result.data)
            resolve(result.data)
            //this.service.WritelogToCloudWatch(result)
            //this.service.getSTSTempCredentials.next(result.data);
          },(err)=>{
            console.log(err)
            //this.service.WritelogToCloudWatch(err)
            reject(err)

          }
            );
    
        } catch (error) {
          console.log(error)
          reject(error)
          //this.service.WritelogToCloudWatch(error)

    
        }
    
      }

       );
      
}
}


