// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  mfaURL:'https://secureidentity.schneider-electric.com/identity/VFP_IDMSMFASettings?app=PASupportWebsite&mfaContext=setup',
  ipify:'https://api.ipify.org/?format=json',
  production: true,
  currentConfig: 'prod',
  region: 'us-east-1',
  endPoint: 'https://sw6evuai2jehhevapbe76qos2e.appsync-api.us-east-1.amazonaws.com/graphql',
  loginUrl: 'https://secureidentity.schneider-electric.com/identity/idplogin?app=PASupportWebsite&response_type=code&client_id=3MVG9yZ.WNe6byQAvlJ.HwD94PVsdBJ_Oj6tQnC1C3HcLWxdbLkY5il7hKIvNfrrrUZXlFQD8KNJTx0Gxqf.k&redirect_uri=https://pasupport.se.com/home',
  troubleLoggingInUrl: 'https://secureidentity.schneider-electric.com/identity/VFP_IDMSSelfHelp?app=PASupportWebsite&lang=en-US&redirect_uri=https://pasupport.se.com/home&response_type=code',
  // loginUrl: 'https://secureidentity.schneider-electric.com/identity/idplogin?app=PASupportWebsite&response_type=code&client_id=3MVG9yZ.WNe6byQAvlJ.HwD94PVsdBJ_Oj6tQnC1C3HcLWxdbLkY5il7hKIvNfrrrUZXlFQD8KNJTx0Gxqf.k&redirect_uri=https://d31ewouf5qu5em.cloudfront.net/home',
  // troubleLoggingInUrl: 'https://secureidentity.schneider-electric.com/identity/VFP_IDMSSelfHelp?app=PASupportWebsite&lang=en-US&redirect_uri=https://d31ewouf5qu5em.cloudfront.net/home&response_type=code',
  changePassword: 'https://secureidentity.schneider-electric.com/identity/idmspasswordupdate?app=PASupportWebsite',
  changeEmail: 'https://secureidentity.schneider-electric.com/identity/changeemailmobile?app=PASupportWebsite',
  logOutUrl: 'https://secureidentity.schneider-electric.com/identity/VFP_IDMS_IDPSloInit?post_logout_redirect_uri=https://pasupport.se.com/pre-login&app=PASupportWebsite',
  // logOutUrl: 'https://secureidentity.schneider-electric.com/identity/VFP_IDMS_IDPSloInit?post_logout_redirect_uri=https://d31ewouf5qu5em.cloudfront.net/pre-login&app=PASupportWebsite',
  supportUrl: 'https://pasupport.schneider-electric.com/landing/landing.asp?smode=support',
  softwareUrl: 'https://pasupport.schneider-electric.com/landing/landing.asp?smode=software',
  documentsUrl: 'https://pasupport.schneider-electric.com/landing/landing.asp?smode=documents',
  addSiteUrl: 'https://pasupport.schneider-electric.com/MyCompany/MyCompanyFrame2010.asp?mode=13',
  editSiteUrl: 'https://pasupport.schneider-electric.com/MyCompany/MyCompanyFrame2010.asp?mode=12',
  // federatedSearchUrl: 'https://pasupport.schneider-electric.com/fedsearch/?token=',
  federatedSearchUrl :'https://pasupport.schneider-electric.com/pasearch/api/process-automation?token=',
  siteMapUrl: 'https://mphasis.invisionapp.com/share/CXYLNJJK5WQ#/screens',
  privacyAndCookiesPolicyUrl: 'https://www.se.com/us/en/about-us/legal/data-privacy.jsp',
  termsOfUserUrl: 'https://www.se.com/us/en/about-us/legal/terms-of-use.jsp',
  changeYourCookiesSettingsUrl: 'https://www.se.com/us/en/',
  privacyNotice: 'https://secureidentity.schneider-electric.com/identity/VFP_IDMSPrivacySelector?app=mySchneiderWeb&lang=en&privacyNotice=showPrivacyNotice',
  privacyPolicy: 'https://secureidentity.schneider-electric.com/identity/VFP_IDMSPrivacySelector?app=mySchneiderWeb&lang=en',
  reCaptchKey: '6LcZSpgaAAAAANSHOCNRH2Ok2YEJ0iH4zW_NJzE9', 
  viewMoreNotification: 'https://pasupport.schneider-electric.com/MoreNewsFrame2008.asp',
  emailGcsInfo:'mailto: systems.support@schneider-electric.com',
  foxboroDCSUrl:'https://www.se.com/ww/en/work/products/industrial-automation-control/foxboro-dcs',
  processInstrumenatationUrl:'https://www.se.com/ww/en/brands/foxboro/foxboro-measurement-instrumentation-products.jsp',
  foxboroSCADA:'https://www.se.com/ww/en/product-range/63678-foxboro-scada-rtus/?parent-subcategory-id=6040&filter=business-1-industrial-automation-and-control&subNodeId=12366859713en_WW',
  triconexUrl:'https://www.se.com/ww/en/work/products/industrial-automation-control/triconex-safety-systems/',
  buyAutomationUrl:'https://www.buyautomation.com/showroom/default.asp',
  cyberSecurityUrl:'https://www.se.com/ww/en/work/solutions/cybersecurity/industrial-cybersecurity.jsp',
  learningServicesUrl: 'https://palearningservices.se.com/',
  schniderElectricUrl:'https://www.se.com/ww/en/work/solutions/industrial-process-automation/',
  baarnGenralEmail: 'mailto:EMEAGCS.support@schneider-electric.com',
  baarnTechnicalSupport: 'mailto:Support-EMEA.PA@schneider-electric.com',
  cairoGenralEmail: 'mailto:EMEAGCS.support@schneider-electric.com',
  cairoTechnicalSupport:'mailto:Support-MENA.PA@schneider-electric.com',
  americasGeneralEmail:'mailto:systems.support@schneider-electric.com',
  americasTechnicalSupport:'mailto:PA.Support@schneider-electric.com',
  chennaiGenralEmail:'mailto:APAC_systems.support@schneider-electric.com',
  chennaiTechnicalSupport:'mailto:Support-India.PA@schneider-electric.com',
  generalEmailSanghai:'mailto:APAC_systems.support@schneider-electric.com',
  sanghaiTechnicalSupport:'mailto:Support-APAC.PA@schneider-electric.com',
  scadaAndTelemetryGeneralEmail : 'mailto:systems.support@schneider-electric.com',
  scadaandTelemetryTechnicalSupport:'mailto:PA.Support@schneider-electric.com',
  scadapackTechnicalSupport:'mailto: supportTRSS@se.com',
  foxboroGeneralEmail:'mailto:systems.support@schneider-electric.com',
  foxboroTechnicalSupport:'mailto:fielddevices-support@schneider-electric.com',
  samir:"ZGEyLXl4dW1teWVzaHphaWZkaW1qczdpNnJ0bHdt",
  seUrl: 'https://www.se.com',
  remoteServicesUrl: 'https://pasupport.schneider-electric.com/IARP/RemoteServicesSiteDetailCustomer2011.asp',
  employeeLoginUrl: 'https://secure-identity.schneider-electric.com/identity/idplogin?app=GCSWeb&response_type=code&client_id=3MVG9yZ.WNe6byQAvlJ.HwD94PX7ApQaGtqiBGIBhjbw_EiJ_mPHbkrz1Jq9FjEYdj0QIKyhDkfsVfFxUW5En&redirect_uri=https://pasupport.schneider-electric.com/home.asp',
  fisrtTimeRegistrationUrl :  'https://secure-identity.schneider-electric.com/identity/idplogin?app=GCSWeb&response_type=code&client_id=3MVG9yZ.WNe6byQAvlJ.HwD94PX7ApQaGtqiBGIBhjbw_EiJ_mPHbkrz1Jq9FjEYdj0QIKyhDkfsVfFxUW5En&redirect_uri=https://pasupport.schneider-electric.com/home.asp',
  feedbackUrl: 'https://pasupport.schneider-electric.com/Feedbackframe.asp',
  aboutCaseStatusUrl:'https://pasupport.schneider-electric.com/case/CaseStatusDefFrame.ASP',
  legacyWebsiteLink:'https://pasupport.schneider-electric.com/homec.asp',
  tecperDownloadUrl: 'https://api.se.com/v1/',
  bfoCaselistEndPoint: 'https://api.se.com/v1/customer-support/',
  feedbackGcs:'https://pasupport.schneider-electric.com/FeedbackGCS.asp',
  errorBfoJson : 'assets/Error-handling/Error-Handling_withAPI_URL_prod.json',
  seGcsProgAccessKeyId :'',
  seGcsProgSecret :'',
  googleAnalyticsMeasurementId:'G-8BLCNZEQZT',
  feedbackFromEmail:"GCSMaster@schneider-electric.com",
  feedbackMailSource:"GCSWeb2020",
  secretApiKey:"apikey",
  numberOfCharToTruncateAfter:50
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
