import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables } from '../constants';

@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlCaseManagementService {

  constructor(private apollo: Apollo) { }

  public getUserDetails(code: string): void {
    let userLoginQuery: QueryRef<any>;
    sessionStorage.setItem(SessionVariables.isUserLoggedIn, '0');
    const codeParam = `${code}`;
    userLoginQuery = this.apollo.watchQuery({
      query: gql`query MyQuery {
        getLoggedInUserInfo(input: {code: "${codeParam}"}) {
          CFID
          accountId
          ail
          ailApplications
          ailPrograms
          annualRevenue
          bFOAccountID
          bFOContactID
          classLevel1
          companyCity
          companyCountryCode
          companyFederationId
          companyName
          companyStateOrProvinceCode
          companyStreet
          companyZipCode
          company_code
          contactGoldenID
          contactId
          countryCode
          currency
          customer_code
          email
          emailOptIn
          enabled
          federatedId
          firstName
          headquarter
          identityType
          idmsFederatedId
          isInternal
          languageCode
          lastName
          level_code
          marketSegment
          middleName
          trustLevel
          userContext
          userEntitlement
          userGroup
          userId
          user_code
          fedratedSearchToken
          accessToken
          region
          apigeeAccessToken
        }
      }
       `,
    });

    userLoginQuery.valueChanges.subscribe((result) => {
      sessionStorage.setItem(SessionVariables.isUserLoggedIn, JSON.stringify(result));
      sessionStorage.setItem(SessionVariables.isLoggedIn, '1');
    });
  }

}
