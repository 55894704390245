import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables } from '../constants';
import { DynamicComponentCreateService } from '../gcs.business.services/dynamic-component-create.service';

@Injectable({
  providedIn: 'root'
})


export class GcsHowToVideosService {

  constructor(private apollo: Apollo, public service: DynamicComponentCreateService) { }

  public getVideosLink(GCS: string): any {
    let videosLinkQuery: QueryRef<any>;
    videosLinkQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getGCSVideos {
          URL
          title
        }
      }

  `,
    });
    videosLinkQuery.valueChanges.subscribe((result) => {
      const resultData = result && result.data;
      const videos = resultData && resultData.getGCSVideos;
      sessionStorage.setItem(SessionVariables.videosLinks, JSON.stringify(videos));
      this.service.getvideoslinksservice.next(true);
    });
  }

}