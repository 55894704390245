//grap all the master lookup data and store it session here
//create session variables and constant.ts
//make a call to this app-init service when the app load at the heigst level
//instead of making graphql data from session( dropdowns)
import { Injectable } from '@angular/core';
import { GcsGraphQlCaseFiltersServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.case-filters.service.secure';
import { SessionVariables } from '../constants';
import { GcsBusinessCommonService } from '../../gcs.services/gcs.business.services/gcs.business.common.service';
import { DynamicComponentCreateService } from '../../gcs.services/gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlCreateNewCaseService } from '../gcs.graph-ql.services/gcs-graph-ql-create-new-case.service';
import { GcsGraphQlGCSSitesServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.gcs-sites.service.secure';
import { GcsGraphQlRemoteServicesServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.remote-services.service.secure';
import { GcsGraphQlGCSUsersServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.gcs-users.service.secure';
import { Router } from '@angular/router';




@Injectable({
    providedIn: 'root'
})

export class GcsAppInitService {

    constructor(private gcsGraphQlCaseFiltersServiceSecure: GcsGraphQlCaseFiltersServiceSecure,
        private gcsBusinessCommonService: GcsBusinessCommonService,
        private dynamicComponentService: DynamicComponentCreateService,
        private gcsGraphQlCreateNewCaseService: GcsGraphQlCreateNewCaseService,
        private gcsGraphQlGCSSitesServiceSecure: GcsGraphQlGCSSitesServiceSecure,
        private gcsGraphQlRemoteServicesServiceSecure: GcsGraphQlRemoteServicesServiceSecure,
        private gcsGraphQlGcsUsersServiceSecure: GcsGraphQlGCSUsersServiceSecure,
        private router: Router) { }


    public caseFilter(fedId: string): void {
        return this.gcsGraphQlCaseFiltersServiceSecure.getFilterDetails(fedId);
    }
    public gcsUser(userCode: number) {
        return this.gcsGraphQlGcsUsersServiceSecure.getGCSUsers(userCode);
    }
    public gcsSite(userCode: number) {
        return this.gcsGraphQlGCSSitesServiceSecure.getMyCompanySites(userCode);
    }

    public gcsRemote(userCode: number, localCompanyCode?: number) {
        return this.gcsGraphQlRemoteServicesServiceSecure.getRemoteServices(userCode, localCompanyCode);
    }
    public gcsCreateCaseType() {
        return this.gcsGraphQlCreateNewCaseService.getCaseTypesDropdownList();
    }

    public getGcsAccountDetails() {
        this.gcsGraphQlCaseFiltersServiceSecure.getAllGCSAccountDetails();
    }

    public getCaselistColumns(): void{
        this.gcsBusinessCommonService.getCaseListColumn();
    }

    public getCaselist(): void {
        const accessToken = this.dynamicComponentService.getIds('accessToken');
        const bfoContactId = this.dynamicComponentService.getIds('bFOContactID');
        const levelCode = this.dynamicComponentService.getIds('level_code');
        const userGroup = this.dynamicComponentService.getIds('userGroup');
        const userCode = this.dynamicComponentService.getIds('user_code');

        const bfoAccountId = this.dynamicComponentService.getIds('bFOAccountID');
        const isAILSet = this.dynamicComponentService.getIds('isAILSet');

        const Isparentaccess = this.dynamicComponentService.getIds('Isparentaccess');

        let isClosedConsider: any;
        let routerUrl: any;
        if (this.router.url.includes('case-management/cases-home')) {
            isClosedConsider = 1;
            routerUrl = 'cmPage';
        } else {
            isClosedConsider = 0;
            routerUrl = 'homePage';
        }
        const sessionFilterCompany = JSON.parse(sessionStorage.getItem(SessionVariables.companyFilter)) || null;
        let tabIndex = 1;
        if(sessionFilterCompany){
            tabIndex = 0;
        }
        this.gcsBusinessCommonService.getCaselist(accessToken, isClosedConsider, bfoContactId,
            routerUrl, tabIndex, levelCode, userGroup, userCode);
    }

    public gcsBannerMessage(): void{
        this.gcsBusinessCommonService.getBannerMessage();
    }

    public getBellNotificationDetails(): void{
        const getUserCode = this.dynamicComponentService.getIds();
        this.gcsBusinessCommonService.getBellNotificationsAndAlerts(getUserCode.user_code);
    }

    public getOverviewStatistics(): void{
        const accessToken = this.dynamicComponentService.getIds('accessToken');
        const bfoContactId = this.dynamicComponentService.getIds('bFOContactID');
        const levelCode = this.dynamicComponentService.getIds('level_code');
        const userGroup = this.dynamicComponentService.getIds('userGroup');
        const userCode = this.dynamicComponentService.getIds('user_code');
        const bfoAccountId = this.dynamicComponentService.getIds('bFOAccountID');
        const isAILSet = this.dynamicComponentService.getIds('isAILSet');

        this.gcsBusinessCommonService.getOverViewInfo(userCode, bfoContactId, accessToken, bfoAccountId, levelCode, userGroup, isAILSet);
    }

    public myCompanyOverview(): void{
        const accessToken = this.dynamicComponentService.getIds('accessToken');
        const levelCode = this.dynamicComponentService.getIds('level_code');
        const userGroup = this.dynamicComponentService.getIds('userGroup');
        const userCode = this.dynamicComponentService.getIds('user_code');
        const isAILSet = this.dynamicComponentService.getIds('isAILSet');

        const Isparentaccess = this.dynamicComponentService.getIds('Isparentaccess');
        this.gcsBusinessCommonService.getCompanyInfo(Isparentaccess, userCode, accessToken, levelCode, userGroup, isAILSet);
    }

}