import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables } from '../constants';
import { DynamicComponentCreateService } from '../gcs.business.services/dynamic-component-create.service';

@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlRegionalGcsInfoService {

  constructor(private apollo: Apollo, private service: DynamicComponentCreateService) { }

  public getRegionalGCSInfo(): void {
    const region = this.service.getIds('region');
    let regionalGcsInfoQuery: QueryRef<any>;
    regionalGcsInfoQuery = this.apollo.watchQuery({
      query: gql`query{
      getRegionalGCSInfo(id:"${region}")
      {
        region
        contactInformation
        {
          phone
          alt_ph
          fax
          email
        }
        mailingAddress
      }
    }
      `,
    });

    regionalGcsInfoQuery.valueChanges.subscribe(result => {
      sessionStorage.setItem(SessionVariables.regionalGcsInfo, JSON.stringify(result.data.getRegionalGCSInfo));
      this.service.regionalGcsInfo.next(true);
    });
  }
}
