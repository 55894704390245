import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, ErrorCodes } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';

@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlProductFamilyServiceSecure {

  constructor(private apollo: Apollo, public service: DynamicComponentCreateService, private gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure) { }

  public getProductFamily(userCode: number): any {
    let getProductFamilyQuery: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    getProductFamilyQuery = this.apollo.watchQuery({
      query: gql`
        query MyQuery {
          getProductFamilySecure(input: {JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}"}) {
            isEmailSubscribed
            productFamilyList {
              brandCode
              brandName
              userCount
            }
          }
        }
      `,
    });
    getProductFamilyQuery.valueChanges.subscribe((result) => {
      sessionStorage.setItem(SessionVariables.getProductFamily, JSON.stringify(result.data.getProductFamilySecure));
      this.service.getproductFamilyservice.next(true);
    },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.getProductFamily(userCode);
              }
            })
        }
      }
    );
  }

  public async setProductFamily(brandCodeList: any[], userCode: number, isEmailSubscribed: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let ip = sessionStorage.getItem(SessionVariables.ip);
      ip = ip ? ip : '';
      const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
      let screenId = '';
      this.apollo.mutate({
        mutation: gql`
      mutation {
        setProductFamilySecure(input: {
          brandCodeList: [${brandCodeList}],
          JWT: "${sessionToken}",
           screenId: "${screenId}",
          ipAddress: "${ip}"
          isEmailSubscribed:${isEmailSubscribed}
        }
      )
    }
    `,
      }).subscribe(data => {
        resolve('');
        sessionStorage.removeItem(SessionVariables.notificationsAndAlerts)
      },
        (err) => {
          const errorMessage = err.message;
          if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
            //code to refresh token and to call again
            this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
              (result) => {
                const data = result.data.renewToken;
                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                  // probably should logout the user
                } else if (data.JWT) {
                  sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                  const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                  updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                  updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                  updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                  updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                  sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                  this.setProductFamily(brandCodeList, userCode, isEmailSubscribed);
                }
              })
          }
        });
    });
  }

}
