import { Observable } from 'rxjs';
import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';


@Injectable({
  providedIn: 'root'
})
export class GcsGraphQlProfileInformationService {

  constructor(private apollo: Apollo) { }

  public getProfileInformation(idmsFederatedId: string): Observable<any> {
    let userLoginQuery: QueryRef<any>;
    const id = `${idmsFederatedId}`;
    userLoginQuery = this.apollo.watchQuery({
      query: gql`query MyQuery {
        getProfileInformation(input: {idmsFederatedId: "${id}"}) {
          AD_Domain
          CompanyCategory
          Country_Desc
          JWT
          Region_Code
          Region_Desc
          SAPLinked
          State_Code
          State_Name
          UAL_GUID
          UserGroup
          bFOAccountID
          bFOContactID
          company
          company_code
          country_code
          industryLowLevel
          level_code
          sAMAccountName
          sap_contact_ID
          sap_employee_id
          user_MailStop
          user_address
          user_city
          user_cn
          user_code
          user_companydivision
          user_companydivision_OLD
          user_country
          user_email
          user_epush
          user_fax
          user_jobtitle
          user_lastvisiteddate
          user_name
          user_phone
          user_phone_ext
          user_postalcode
          user_state
          user_surname
        }
      }
       `,
    });

    return userLoginQuery.valueChanges;
  }
}
