import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable, NgZone } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, screenIdsForTelemetry, ErrorCodes, TimedOutMessage } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';
import { DialogService } from "@progress/kendo-angular-dialog";
@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlMyCompanyModuleApisServiceSecure {

  private companyHomeQuery: QueryRef<any>;
  constructor(
    private apollo: Apollo,
    private service: DynamicComponentCreateService,
    public gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure,
    private dialogService: DialogService, private ngZone: NgZone
  ) { }

  public loadCompanyHome(Isparentaccess: number, userCode: number, accessToken: string, levelCode: number, userGroup: number, isAILSet: boolean): void {
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = screenIdsForTelemetry['my-company-company-home'].screenId;
    this.companyHomeQuery = this.apollo.watchQuery({
      query: gql`
      query {
        myCompany(input: {
            Isparentaccess: ${Isparentaccess},
            JWT: "${sessionToken}",
            screenId: "${screenId}",
            ipAddress: "${ip}",
            level_code: ${levelCode},
            userGroup: ${userGroup},
            JWT: "${sessionToken}",
            screenId: "${screenId}",
            ipAddress: "${ip}",
            isAILSet:${isAILSet},
        }) {
          isBfoFailed
          parentCompany
          companyOutputValue {
            region
            location
            company
            gcsUserCount
            hot
            open
            tot
            gen
            oss
            mep
            tec
            per
            rws
            sitesCount
            foxboroDcsCount
            triconexCount
            plcCount
            remoteServicesCount
            localCompanyCode
          }
          totalCount {
            gcsUserTotalCount
            hotTotalCount
            openTotalCount
            totTotalCount
            genTotalCount
            ossTotalCount
            mepTotalCount
            tecTotalCount
            perTotalCount
            rwsTotalCount
            sitesTotalCount
            foxboroDcsTotalCount
            triconexTotalCount
            plcTotalCount
            remoteServicesTotalCount
          }
        }
      }
      `,
    });
    this.companyHomeQuery.valueChanges.subscribe(
      (result) => {
        const regions = [];
        const locations = [];
        if (result.data.myCompany.companyOutputValue.length >= 1) {
          result.data.myCompany.companyOutputValue.forEach((row: any) => {
            regions.push(row.region);
            locations.push(row.location);
          });
        }
        regions.map((val, index) => {
          if (regions.indexOf(val) !== index) {
            regions[index] = '';
          }
        });
        locations.map((val, index) => {
          if (locations.indexOf(val) !== index) {
            locations[index] = '';
          }
        });
        // result.data.myCompany.companyOutputValue.map((row: any, index: any) => {
        //   row.region = regions[index];
        //   row.location = locations[index];
        // });
        let updatedData = result.data.myCompany.companyOutputValue.map((row: any, index: any) => {
          return {
              ...row,
              region: regions[index],
              location: locations[index]
            };
        });

        const gridData = updatedData; //result.data.myCompany.companyOutputValue;
        const totals = result.data.myCompany.totalCount;
        const isBfoFailed = result.data.myCompany.isBfoFailed;
        const gridDataParent = result.data.myCompany.parentCompany;
        const gridView = gridData;
        const total = gridData.length;

        const makeObject = {
          isBfoFailed,
          gridData,
          totals,
          gridDataParent,
          gridView,
          total
        };
        sessionStorage.setItem(SessionVariables.companyHomeData, JSON.stringify(makeObject));
        this.service.myCompanyHomeComponentBehaviourSubj.next(true);
      },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.loadCompanyHome(Isparentaccess, userCode, accessToken, levelCode, userGroup, isAILSet);
              }
            });
        }
        // If execution time out error then show popup and stop spinning
        else if (errorMessage.includes(ErrorCodes.Execution_TimeOut)) {
          this.handleTimeOutError();
          this.addTimeoutLog();
          sessionStorage.setItem(SessionVariables.companyHomeData, JSON.stringify([]));
          this.service.myCompanyHomeComponentBehaviourSubj.next(true);
        }
      }
    );
  }
  public handleTimeOutError() {
    this.ngZone.run(() => {
      this.dialogService
        .open({
          title: TimedOutMessage.titleMessage,
          content: TimedOutMessage.timedOutMessage,
          actions: [{ text: "OK", primary: true }],
          width: 450,
          height: 200,
          minWidth: 250,
        })
        .result.subscribe(result => {
          console.log("OK");
          // console.log(result);
        });
    });
  }
  public addTimeoutLog() {
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    let screenId = '';
    let addLogQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery{
        addToLog(input:{
          APIName: "myCompany",
          JWT: "${sessionToken}",
          ipAddress: "${ip}",
          logMessage: "${TimedOutMessage.logMessage}",
          logType: "${TimedOutMessage.logType}",
          screenId: "${screenId}"
        })
      }
      `
    });
    addLogQuery.valueChanges.subscribe(
      (result) => {
      }
    )
  }
}
