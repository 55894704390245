// Inject the the new secure service below - Anand
import { GcsGraphQlCaseListServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.case-list.service.secure';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionVariables } from '../constants';
// Inject the new secure service for below -Anand
import { GcsGraphQlUserDetailServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.user-detail.service.secure';
import { GcsGraphQlCaseManagementService } from '../gcs.graph-ql.services/gcs.graph-ql.case-management.service';
import { GcsGraphQlOverViewUserInfoService } from '../gcs.graph-ql.services/gcs.graph-ql.over-view-user-info.service';
import { GcsGraphQlIdmsInfoService } from '../gcs.graph-ql.services/gcs.graph-ql.idms-info.service';
import { GcsGraphQlRegionalGcsInfoService } from '../gcs.graph-ql.services/gcs.graph-ql.regional-gcs-info.service';
import { GcsGraphQlHeaderNavChipsService } from '../gcs.graph-ql.services/gcs.graph-ql.header-nav.service';
import { GcsGraphQlRearrangeViewService } from '../gcs.graph-ql.services/gcs.graph-ql.rerrange-view.service';
import { CaseListType } from '../../gcs.types/gcs.types.CaseListType';
import { GcsGraphQlCustomerRegistrationFirstService } from '../gcs.graph-ql.services/gcs-graph-ql-customer-registration-first.service';
import { GcsGraphQlSessionCacheService } from '../gcs.graph-ql.services/gcs.graph-ql.session-cache.service';
import { SessionCacheType } from '../../gcs.types/gcs.types.SessionCacheType';
import { CustomerRegistationService } from '../customer-registation.service';
import { GcsGraphQlCreateNewCaseService } from '../gcs.graph-ql.services/gcs-graph-ql-create-new-case.service';
import { GcsGraphQlProfileInformationService } from '../gcs.graph-ql.services/gcs.graph-ql.profile-information.service';
import { GcsHowToVideosService } from '../gcs.graph-ql.services/gcs.graph-ql.how-to-videos.service';
import { GcsIpService } from './gcs.ip.service';
import { GcsGraphQlOverViewInfoServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.over-view-info.service.secure';
import { GcsGraphQlCreateNewCaseServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs-graph-ql-create-new-case.service.secure';
import { GcsGraphQlCaseTypeServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.case-type.service.secure';
import { GcsGraphQlCaseFiltersServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.case-filters.service.secure';
import { GcsGraphQlSessionIdServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.session-id.service.secure';
import { GcsGraphQlProfileInformationServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.profile-information.service.secure';
import { GcsGraphQlSubmitCaseServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.submit-case.service.secure';
import { GcsGraphQlCompanyInfoServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.company-info.service.secure';
import { GcsGraphQlMyCompanyModuleApisServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.my-company-module-apis.service.secure';
import { GcsGraphQlChangeEmailServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.gcs-change-email.service.secure';
import { GcsGraphQlQuickLinksServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.quick-links.service.secure';
import { GcsGraphQlProductFamilyServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.product-family.service.secure';
import { GcsGraphQlNotificationsAndAlertsServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.notifications-and-alerts.service.secure';
import { GcsGraphQlRearrangeViewServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.rerrange-view.service.secure';
import { GcsGraphQlsetbBfoLogsServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.gcs-set-bfoLogs.service.secure';
import { GcsGraphQlCaseListColumnsSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.show-hide-columns.service.secure';
import { GcsGraphQlRemoteWatchDetailsServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.remote-watch-details.service.secure';
import { GcsGraphQlCreateNewSiteServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs-graph-ql-create-new-site.service.secure';
import { GcsGraphQlGCSSitesServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.gcs-sites.service.secure';
import { GcsGraphQlSiteEquipmentDetailsServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.site-equipment-details.service.secure'
import { GcsGraphQlSystemAssetViewerServiceSecure } from '../gcs.graph-ql.services/gcs.graph-ql.services.secure/gcs.graph-ql.system-asset-viewer.service.secure'
@Injectable({
  providedIn: 'root'
})

export class GcsBusinessCommonService {


  constructor(
    private gcsGraphQlCaseManagementService: GcsGraphQlCaseManagementService,
    private gcsGraphQlOverViewUserInfoService: GcsGraphQlOverViewUserInfoService,
    private gcsGraphQlHeaderNavService: GcsGraphQlHeaderNavChipsService,
    private gcsGraphQlRearrangeViewService: GcsGraphQlRearrangeViewService,
    private gcsGraphQlIdmsInfoService: GcsGraphQlIdmsInfoService,
    private gcsGraphQlRegionalGcsInfoService: GcsGraphQlRegionalGcsInfoService,
    private gcsGraphQlCustomerRegistrationFirstService: GcsGraphQlCustomerRegistrationFirstService,
    private customerRegistationService: CustomerRegistationService,
    private gcsGraphQlSessionCacheService: GcsGraphQlSessionCacheService,
    private gcsGraphQlCreateNewCaseService: GcsGraphQlCreateNewCaseService,
    private gcsGraphQlProfileInformationService: GcsGraphQlProfileInformationService,
    private gcsHowToVideosService: GcsHowToVideosService,
    // start adding secure services below. 2 are added for now, you'll start adding all secure services below - Anand
    private gcsGraphQlCaseListServiceSecure: GcsGraphQlCaseListServiceSecure,
    private gcsGraphQlUserDetailServiceSecure: GcsGraphQlUserDetailServiceSecure,
    private gcsIpService: GcsIpService,
    private gcsGraphQlOverViewInfoServiceSecure: GcsGraphQlOverViewInfoServiceSecure,
    private gcsGraphQlCreateNewCaseServiceSecure: GcsGraphQlCreateNewCaseServiceSecure,
    private gcsGraphQlCaseTypeServiceSecure: GcsGraphQlCaseTypeServiceSecure,
    private gcsGraphQlCaseFiltersServiceSecure: GcsGraphQlCaseFiltersServiceSecure,
    private gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure,
    private gcsGraphQlProfileInformationServiceSecure: GcsGraphQlProfileInformationServiceSecure,
    private gcsGraphQlSubmitCaseServiceSecure: GcsGraphQlSubmitCaseServiceSecure,
    private gcsGraphQlCompanyInfoServiceSecure: GcsGraphQlCompanyInfoServiceSecure,
    private gcsGraphQlMyCompanyModuleApisServiceSecure: GcsGraphQlMyCompanyModuleApisServiceSecure,
    private gcsGraphQlChangeEmailServiceSecure: GcsGraphQlChangeEmailServiceSecure,
    private gcsGraphQlQuickLinksServiceSecure: GcsGraphQlQuickLinksServiceSecure,
    private gcsGraphQlProductFamilyServiceSecure: GcsGraphQlProductFamilyServiceSecure,
    private gcsGraphQlNotificationsAndAlertsServiceSecure: GcsGraphQlNotificationsAndAlertsServiceSecure,
    private gcsGraphQlRearrangeViewServiceSecure: GcsGraphQlRearrangeViewServiceSecure,
    private gcsGraphQlsetbBfoLogsServiceSecure: GcsGraphQlsetbBfoLogsServiceSecure,
    private gcsGraphQlCaseListColumnsSecure: GcsGraphQlCaseListColumnsSecure,
    private GcsGraphQlRemoteWatchDetailsServiceSecure: GcsGraphQlRemoteWatchDetailsServiceSecure,
    private GcsGraphQlCreateNewSiteServiceSecure: GcsGraphQlCreateNewSiteServiceSecure,
    private GcsGraphQlGCSSitesServiceSecure: GcsGraphQlGCSSitesServiceSecure,
    private GcsGraphQlSiteEquipmentDetailsServiceSecure : GcsGraphQlSiteEquipmentDetailsServiceSecure,
    private GcsGraphQlSystemAssetViewerServiceSecure : GcsGraphQlSystemAssetViewerServiceSecure
  ) { }

  public isUserLoggedIn(): void {
    if (sessionStorage.getItem(SessionVariables.isUserLoggedIn) === '1') {
      // return true;
    } else {
      this.getUserDetails();
    }
  }

  public showFooter(): boolean {
    return sessionStorage.getItem(SessionVariables.showFooter) === '1' ? true : false;
  }

  private getUserDetails(): void { // Store values in session variable, instead of just checking the Param
    let param: string;

    // We need to go plain JavaScript logic here, because Angular routing is not available
    // in this business service. Its an exception, else we don't use JavaScript any where in Angular -Anand
    const urlParam: string = window.location.search;
    const splitUrlArray = urlParam.split('=');
    if (splitUrlArray[0] === '?code') {
      param = splitUrlArray[1].split('&')[0];
      // Also get the next param, or the whole string for China Auth here. I was just getting the first param 'code'
      // and ignoring everything else. You'll need also to get the China Auth param here, what ever its called -Anand
    }
    sessionStorage.setItem(SessionVariables.code, param);
    // You'll need to also store the China Auth param here, so that client also knows that its a China Auth. -Anand


    if (param === undefined || param === null) {
      sessionStorage.setItem(SessionVariables.isUserLoggedIn, '0');
      sessionStorage.setItem(SessionVariables.code, param);
    } else { // this is a hack job just to test.
      //this.gcsGraphQlUserDetailService.getUserDetails(param);
      // uncomment the following line when everythign is wired up, to call secure service, and commend the line above. -Anand
      this.gcsGraphQlUserDetailServiceSecure.getUserDetails(param);
    }
  }

  public getSessionCache(fedId: string, tabIndex: number): SessionCacheType {
    // You need to wirte up logic in session object to get  session data out with fedID and TabIndex -Anand
    return sessionStorage.getItem(SessionVariables.sessionCache) as SessionCacheType;
  }

  public refreshSessionCache(fedId: string, tabIndex: number): SessionCacheType {
    return this.gcsGraphQlSessionCacheService.getSessionCacheData(fedId, tabIndex);
  }

  public clearSessionCache(): boolean {
    sessionStorage.clear();
    return true;
  }

  public getCaselist(accessToken: string, isClosedConsider: number,
    bfoContactId: string, fedId: string, tabIndex: number,
    levelCode: number, userGroup: number, userCode: number): Promise<CaseListType[]> {
    return this.gcsGraphQlCaseListServiceSecure.getCaseList(accessToken,
      isClosedConsider, bfoContactId, fedId, tabIndex, levelCode, userGroup, userCode);
  }

  public getFilterDetails(fedId: string): any {
    return JSON.parse(sessionStorage.getItem(SessionVariables.caseFilters));
  }

  public getIdmsInfo(): any {
    return this.gcsGraphQlIdmsInfoService.getIdmsInfo();
  }

  public getUserData(code: string): any {
    return this.gcsGraphQlCaseManagementService.getUserDetails(code);
  }

  public getCaseTypes(): any {
    return this.gcsGraphQlCaseTypeServiceSecure.getCaseTypesData();
  }

  public getCompanyInfo(Isparentaccess: number, userCode: number, accessToken: string, levelCode: number, userGroup: number, isAILSet: boolean): any {
    return this.gcsGraphQlCompanyInfoServiceSecure.getCompanyInfo(Isparentaccess, userCode, accessToken, levelCode, userGroup, isAILSet);
  }

  public getNotificationsAndAlerts(userCode: number): any {
    return this.gcsGraphQlNotificationsAndAlertsServiceSecure.getNotificationsAndAlerts(userCode)
  }

  public getAllNotificationsAndAlerts(userCode: number, getAllNotifications: boolean =true, title: string = "",
    summary: string = "", product_family: number = null,  override_user_selected: number = 0): any {
      return this.gcsGraphQlNotificationsAndAlertsServiceSecure.getAllNotificationsAndAlerts(userCode, getAllNotifications, title,
        summary, product_family,  override_user_selected)
  }

  public getOverViewInfo(userCode: number, bfoContactId: string,
    accessToken: string, bFOAccountId: string, levelCode: number, usergroup: number, isAILSet: boolean): any {
    this.gcsGraphQlOverViewInfoServiceSecure.getOverViewInfo(userCode, bfoContactId, accessToken, bFOAccountId, levelCode, usergroup, isAILSet);
  }

  public getOverViewUserInfo(): any {
    return this.gcsGraphQlOverViewUserInfoService.getOverviewUserInfo();
  }

  public getHeaderNavChips(menuId: any): any {
    return this.gcsGraphQlHeaderNavService.getHeaderNav(menuId);
  }

  public getBannerMessage(): any {
    return this.gcsGraphQlHeaderNavService.getBannerMessage();
  }

  public updateRearrangeView(reArrangeValue: any): any {
    return this.gcsGraphQlRearrangeViewServiceSecure.updateRearrangeView(reArrangeValue);
  }
  public createUpdateRearrangeView(reArrangeValue: any): any {
    return this.gcsGraphQlRearrangeViewServiceSecure.setRearrangeViewDefault(reArrangeValue);
  }

  public getRearrangeViewItems(fedratedId: string): any {
    return this.gcsGraphQlRearrangeViewServiceSecure.getRearrangeViewFun(fedratedId);
  }
  public getRegionalGCSInfo(): any {
    this.gcsGraphQlRegionalGcsInfoService.getRegionalGCSInfo();
  }

  public getGCSUsers(userCode: number): any {
    return JSON.parse(sessionStorage.getItem(SessionVariables.gcsUsers));
  }

  public getRemoteServices(userCode: number, localCompanyCode: number): any {
    return JSON.parse(sessionStorage.getItem(SessionVariables.remoteServices));
  }

  public getSiteDetails(siteId: any): any{
    this.GcsGraphQlRemoteWatchDetailsServiceSecure.getSiteDetails(siteId);
  }

  public getCustomerSiteInfo(siteId: any): any{
    this.GcsGraphQlRemoteWatchDetailsServiceSecure.getCustomerSiteInfo(siteId);
  }

  public getRemoteServicesEmails(siteId: any): any{
    this.GcsGraphQlRemoteWatchDetailsServiceSecure.getRemoteServicesEmails(siteId);
  }

  public updateRemoteServicesUsers(userCode: number, siteId: any, operation: any): any{
    this.GcsGraphQlRemoteWatchDetailsServiceSecure.updateRemoteServicesUsers(userCode, siteId, operation);
  }

  public getRemoteServicesTopStations(checkDate: any, period: number, siteId: any, severity: any
    ,alarmDesc: any, rwsMessageId: number): any{
    this.GcsGraphQlRemoteWatchDetailsServiceSecure.getRemoteServicesTopStations(checkDate, period, siteId,
      severity, alarmDesc, rwsMessageId);
  }

  public getRemoteServicesTopMessages(checkDate: any, period: number, siteId: any, severity: any
    ,alarmDesc: any, rwsMessageId: number): any{
    this.GcsGraphQlRemoteWatchDetailsServiceSecure.getRemoteServicesTopMessages(checkDate, period, siteId,
      severity, alarmDesc, rwsMessageId);
  }

  public getRemoteServicesEventMessages(checkDate: any, period: number, siteId: any, severity: any
    ,alarmDesc: any, rwsMessageId: number, sort: number): any{
    this.GcsGraphQlRemoteWatchDetailsServiceSecure.getRemoteServicesEventMessages(checkDate, period, siteId,
      severity, alarmDesc, rwsMessageId, sort);
  }

  public getMessageInfo(RwsEventMsgID: any): any{
    this.GcsGraphQlRemoteWatchDetailsServiceSecure.getMessageInfo(RwsEventMsgID);
  }

  public getIndustryTypes(): any{
    this.GcsGraphQlCreateNewSiteServiceSecure.getIndustryTypes();
  }

  public getProductVersions(productFamily: any): Promise<any>{
    return this.GcsGraphQlCreateNewSiteServiceSecure.getProductVersions(productFamily);
  }

  public getAppType(productFamily: any): Promise<any>{
    return this.GcsGraphQlCreateNewSiteServiceSecure.getAppType(productFamily);
  }

  public getDCS(): any{
    this.GcsGraphQlCreateNewSiteServiceSecure.getDCS();
  }

  public createSite(valueObject: any): any {
    return this.GcsGraphQlCreateNewSiteServiceSecure.createSite(valueObject);
  }

  public getQuickLink(fedId: string): any {
    return this.gcsGraphQlQuickLinksServiceSecure.getQuickLink(fedId);
  }

  public getSubmenuChips(menuId: any): any {
    return this.gcsGraphQlHeaderNavService.getHeaderSubMenuList(menuId);
  }

  public updateQuickLink(fedId: string, quickLinkDataList: string): any {
    return this.gcsGraphQlQuickLinksServiceSecure.updateQuickLink(fedId, quickLinkDataList);
  }

  public getProductFamily(userCode: number): any {
    this.gcsGraphQlProductFamilyServiceSecure.getProductFamily(userCode);
  }

  public async setProductFamily(brandCodeList: any[], userCode: number, isEmailSubscribed: number): Promise<any> {
    return this.gcsGraphQlProductFamilyServiceSecure.setProductFamily(brandCodeList, userCode, isEmailSubscribed);
  }


  public getRearrangeView(fedId: string): any {
    return this.gcsGraphQlRearrangeViewService.getRearrangeViewFun(fedId);
  }

  public getCompanyHomeGrid(Isparentaccess: number, userCode: number, accessToken: string, levelCode: number, userGroup: number, isAILSet: boolean): any {
    this.gcsGraphQlMyCompanyModuleApisServiceSecure.loadCompanyHome(Isparentaccess, userCode, accessToken, levelCode, userGroup, isAILSet);
  }

  public getMyCompanySites(userCode: number): any {
    return JSON.parse(sessionStorage.getItem(SessionVariables.gcsSites));
  }

  public getMyCompanySitesAfterCreateSite(userCode: number): any {
    return this.GcsGraphQlGCSSitesServiceSecure.getMyCompanySites(userCode);
  }

  public getCustomerRegistrationEmail(email: any): any {
    this.gcsGraphQlCustomerRegistrationFirstService.getCustomerRegistrationEmail(email);
  }
  public getCustomerRegistrationLocation(): any {
    this.gcsGraphQlCustomerRegistrationFirstService.getCustomerRegistrationLocation();
  }
  public getCustomerRegistrationStateLocation(countryCode: any): any {
    this.gcsGraphQlCustomerRegistrationFirstService.getCustomerRegistrationStateLocation(countryCode);
  }
  public getCustomerRegistrationProductFamily(): any {
    this.gcsGraphQlCustomerRegistrationFirstService.getCustomerRegistrationProductFamily();
  }
  public customerRegistrationSubmit(formData: any): any {
    this.gcsGraphQlCustomerRegistrationFirstService.customerRegistrationSubmit(formData);
  }

  public getCaseTypesDropdownList(): void {
    return JSON.parse(sessionStorage.getItem(SessionVariables.getCaseTypes));
  }
  public getPriorities(caseTypeCode: string): void {
    return this.gcsGraphQlCreateNewCaseService.getPriorities(caseTypeCode);
  }
  public getCompanies(userCode: number, caseTypeCode: string): void {
    this.gcsGraphQlCreateNewCaseServiceSecure.getCompanies(userCode, caseTypeCode);
  }
  public getSites(caseTypeCode: string, userCode: number, localCompanyCode: number): void {
    this.gcsGraphQlCreateNewCaseServiceSecure.getSites(caseTypeCode, userCode, localCompanyCode);
  }
  public getContacts(partnerCode: string, caseTypeCode: string): void {
    this.gcsGraphQlCreateNewCaseService.getContacts(partnerCode, caseTypeCode);
  }
  public genSubmitCase(valueObject: any): void {
    this.gcsGraphQlSubmitCaseServiceSecure.genSubmitCase(valueObject);
  }
  public getProductFamilies(caseTypeCode: string): void {
    this.gcsGraphQlCreateNewCaseService.getProductFamilies(caseTypeCode);
  }
  public getProductFamiliesForParts(caseTypeCode: string, forPartsList: number): void {
    this.gcsGraphQlCreateNewCaseService.getProductFamiliesForParts(caseTypeCode, forPartsList);
  }
  public getProductGroups(caseTypeCode: string, brandCode: number): void {
    this.gcsGraphQlCreateNewCaseService.getProductGroups(caseTypeCode, brandCode);
  }
  public getProduct(caseTypeCode: string, brandGroupCode: number): void {
    this.gcsGraphQlCreateNewCaseService.getProduct(caseTypeCode, brandGroupCode);
  }
  public getProductVersion(caseTypeCode: string, brandProductCode: number): void {
    this.gcsGraphQlCreateNewCaseService.getProductVersion(caseTypeCode, brandProductCode);
  }
  public getRequestInfo(caseTypeCode: string): void {
    this.gcsGraphQlCreateNewCaseService.getRequestInfo(caseTypeCode);
  }
  public getProfileInformation(idmsFederatedId: string): Observable<any> {
    return this.gcsGraphQlProfileInformationService.getProfileInformation(idmsFederatedId);
  }
  public updateProfileInformation(formData, stateNameShort: string, selectedCountryISOcode: string): Observable<any> {
    return this.gcsGraphQlProfileInformationServiceSecure.updateProfileInformation(formData, stateNameShort, selectedCountryISOcode);
  }
  public getGenSummary(userCode: number, caseNumber: any, caseType: any): void {
    this.gcsGraphQlSubmitCaseServiceSecure.getGenSummary(userCode, caseNumber, caseType);
  }
  public getTECPERSummary(accessToken: number, caseId: any, caseType: any): void {
    this.gcsGraphQlSubmitCaseServiceSecure.getTECPERSummary(accessToken, caseId, caseType);
  }
  public getMEPSummary(userCode: number, caseNumber: any, caseType: any): void {
    this.gcsGraphQlSubmitCaseServiceSecure.getMEPSummary(userCode, caseNumber, caseType);
  }
  public getRequestTypeValues(): void {
    this.gcsGraphQlCreateNewCaseService.getRequestType();
  }
  public getContractTypeValues(): void {
    this.gcsGraphQlCreateNewCaseService.getContractTypes();
  }
  public getShipTo(partnerCode: string): void {
    this.gcsGraphQlCreateNewCaseService.getShipTo(partnerCode);
  }
  public getBillTo(partnerCode: string): void {
    this.gcsGraphQlCreateNewCaseService.getBillTo(partnerCode);
  }
  public getPartValues(input: any, iaEnabled: number, tricEnabled: number): void {
    this.gcsGraphQlCreateNewCaseService.getParts(input, iaEnabled, tricEnabled);
  }
  public getFailureTypeValues(): void {
    this.gcsGraphQlCreateNewCaseService.getFailureType();
  }
  public getTECPERInfo(accessToken: string, userCode: number, bfoContactId: string, bfoAccountId: string): void {
    this.gcsGraphQlCreateNewCaseServiceSecure.getTECPERDetails(accessToken, userCode, bfoContactId, bfoAccountId);
  }
  public getCaseTypeSubscription(userCode: number, caseNumber, caseType): void {
    this.gcsGraphQlSubmitCaseServiceSecure.getCaseTypeSubscription(userCode, caseNumber, caseType);
  }
  public genCaseSubscriptionAdd(object: any): void {
    this.gcsGraphQlSubmitCaseServiceSecure.genAddCaseSubscription(object);
  }
  public genCaseSubscriptionDelete(object: any): void {
    this.gcsGraphQlSubmitCaseServiceSecure.genDeleteCaseSubscription(object);
  }
  public setChangeEmail(userCode: number): any {
    return this.gcsGraphQlChangeEmailServiceSecure.setChangeEmail(userCode);
  }

  public setBfoLog(userCode: number, LogText: string, SourceApp: string, API_HTTP_Code: string, API_Error_Code: string,
    API_URL: string, API_Error_Type: string, API_UserMessage: string, API_DevMessage: string, API_Data: string): any {
    return this.gcsGraphQlsetbBfoLogsServiceSecure.setBfoLog(userCode, LogText, SourceApp, API_HTTP_Code, API_Error_Code, API_URL, API_Error_Type, API_UserMessage, API_DevMessage, API_Data);
  }

  public getVideosLink(GCS: string): any {
    return this.gcsHowToVideosService.getVideosLink(GCS);
  }
  public terOrPerSubmitCase(valueObject: any): void {
    this.gcsGraphQlSubmitCaseServiceSecure.terOrPerSubmitCase(valueObject);
  }
  public getFileTo(): void {
    this.gcsGraphQlCreateNewCaseServiceSecure.getFileTo();
  }
  public getUploadTo(image: any): void {
    this.gcsGraphQlCreateNewCaseServiceSecure.getUploadTo(image);
  }
  public getDownloadTo(uid: string, title: any, filetype: any): any {
    this.gcsGraphQlCreateNewCaseService.getDownloadTo(uid, title, filetype);
  }
  public tecPerRequestClosure(comment: string): void {
    this.gcsGraphQlSubmitCaseServiceSecure.tecPerRequestCaseClosure(comment);
  }
  public tecPerGetComments(): void {
    this.gcsGraphQlSubmitCaseServiceSecure.getTecPerComments();
  }
  public getTecPerComments(): void {
    this.gcsGraphQlSubmitCaseServiceSecure.getTecPerComments();
  }
  public addTECPERComment(selectedValue: string): void {
    this.gcsGraphQlSubmitCaseServiceSecure.addTECPERComment(selectedValue);
  }
  public tecPerPostFile(image: any): void {
    this.gcsGraphQlSubmitCaseServiceSecure.tecPerPostFile(image);
  }
  public genPostFile(image: any ,caseNumber: string): void {
    this.gcsGraphQlSubmitCaseServiceSecure.genPostFile(image,caseNumber);
  }
  public gcsListCaseUploads(caseNumber : String) {
    this.gcsGraphQlSubmitCaseServiceSecure.genListCaseUploads(caseNumber);
  }
  public gcsDownloadFile(uid: string, fileName: any, filetype: any,caseNumber:any)
  {
    this.gcsGraphQlSubmitCaseServiceSecure .getGCSDownloadTo(uid , fileName , filetype ,caseNumber )
  }
  public getFieldServiceTypeValues(): void {
    this.gcsGraphQlCreateNewCaseService.getFieldServiceType();
  }
  public getFieldServiceOssValues(): void {
    this.gcsGraphQlCreateNewCaseService.getFieldServiceOss();
  }

  public getFieldServiceOssTrainingValues(): void {
    this.gcsGraphQlCreateNewCaseService.getFieldServiceOssTraining();
  }
  public getFieldServiceOssLocationValues(): void {
    this.gcsGraphQlCreateNewCaseService.getFieldServiceOssLocation();
  }
  public getFieldServiceOssCourseValues(): void {
    this.gcsGraphQlCreateNewCaseService.getFieldServiceOssCourse();
  }
  public mepCaseSubmit(): void {
    this.gcsGraphQlSubmitCaseServiceSecure.mepCaseSubmit();
  }
  public getIp(): void {
    this.gcsIpService.getIp();
  }
  public getSessionId(): Observable<any> {
    return this.gcsGraphQlSessionIdServiceSecure.getSessionId();
  }
  public setCookieSettings(settingValue: number): any {
    return this.gcsGraphQlUserDetailServiceSecure.setCookieSettings(settingValue);
  }
  public getFieldEngineeringServiceOssValues(): void {
    this.gcsGraphQlCreateNewCaseService.getFieldEngineeringServiceOss();
  }
  public ossSubmitCase(): void {
    this.gcsGraphQlSubmitCaseServiceSecure.ossSubmitCaseMutation();
  }
  public getOssSummary(userCode: number, caseNumber: any, caseType: any): void {
    this.gcsGraphQlSubmitCaseServiceSecure.getOssSummary(userCode, caseNumber, caseType);
  }
  public getCaselistAllOpenCases(accessToken: string, isClosedConsider: number,
    bfoContactId: string, routerUrl: string, tabIndex: number,
    levelCode: number, userGroup: number, userCode: number): Promise<CaseListType[]> {
    return this.gcsGraphQlCaseListServiceSecure.getCaseListAllOpenCases(accessToken,
      isClosedConsider, bfoContactId, routerUrl, tabIndex, levelCode, userGroup, userCode);
  }

  public refetchtheHomeData(): void {
    this.gcsGraphQlOverViewInfoServiceSecure.refresh();
    this.gcsGraphQlCompanyInfoServiceSecure.refresh();
    this.gcsGraphQlCaseListServiceSecure.refresh();
  }
  public fetchFirstAllOPencasesData(): void {
    this.gcsGraphQlCaseListServiceSecure.callCaseListAllOpenCasesFirst();
  }
  public getBellNotificationsAndAlerts(userCode: number): any {
    return this.gcsGraphQlNotificationsAndAlertsServiceSecure.getBellNotificationsAndAlerts(userCode);
  }
  public updateBellNotificationsAndAlerts(readNotificationArray: any): any {
    return this.gcsGraphQlNotificationsAndAlertsServiceSecure.updateBellNotificationsAndAlerts(readNotificationArray);
  }

  public async getCaseListColumn(): Promise<any> {
    return this.gcsGraphQlCaseListColumnsSecure.getCaseListColumn();
  }

  public setCaseListColumn(caseListColumns: any) {
    return this.gcsGraphQlCaseListColumnsSecure.setCaseListColumn(caseListColumns);
  }

  public getSiteEquipmentDetails(siteId:any):any {
    this.GcsGraphQlSiteEquipmentDetailsServiceSecure.getSiteEquipments(siteId);
  }

  public getSystemAssetViewer():any {
    this.GcsGraphQlSystemAssetViewerServiceSecure.getSAV();
  }

  public mepGetParts(caseCode:any):any {
    this.gcsGraphQlSubmitCaseServiceSecure.mepGetParts(caseCode)
  }
}
