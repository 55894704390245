import {APP_INITIALIZER, NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, ApolloClient, ApolloLink, createHttpLink, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { environment } from '../environments/environment';
import { createAuthLink } from 'aws-appsync-auth-link';
import { GcsApolloInitService } from './gcs.services/gcs.business.services/gcs.apollo-init.service';

const uri = environment.endPoint; // URL of the GraphQL server
export function createApollo(httpLink: HttpLink, GcsApolloInitService: GcsApolloInitService): ApolloClientOptions<any> {
  const secret = GcsApolloInitService.getSecret();
  const authLink = createAuthLink({
    url: environment.endPoint,
    region: environment.region,
    auth: {
      type: 'API_KEY',
      apiKey: '',
    }
  });

  return {
    link: ApolloLink.from([authLink, httpLink.create({uri})]),
    cache: new InMemoryCache(),
  };
  // return {
  //   link: httpLink.create({uri}),
  //   cache: new InMemoryCache(),
  // };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, GcsApolloInitService],
    },
  ],
})
export class GraphQLModule {}
