import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, screenIdsForTelemetry, ErrorCodes } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlUserDetailServiceSecure {

  public codeParam: string = '';

  constructor(private apollo: Apollo, private service: DynamicComponentCreateService, private gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure, private http: HttpClient) { }

  // new version
  public getUserDetails(param: string): void {
    sessionStorage.setItem(SessionVariables.isUserLoggedIn, '0');
    const codeParam = `${param}`;
    let issValue: string | boolean = sessionStorage.getItem(SessionVariables.iss);
    issValue = issValue != "" ? true : false;
    let userLoginQuery: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    userLoginQuery = this.apollo.watchQuery({
      query: gql`query MyQuery {
        newGetLoggedInUserInfoSecure(input: {
          code: "${codeParam}",
          ipAddress: "${ip}",
          ISS: ${issValue}
        }) {
          CFID
          CFIDList
          Isparentaccess
          JWT
          JWTRefreshToken
          accessToken
          accountId
          # ail
          # ailApplications
          # ailPrograms
          # annualRevenue
          apigeeAccessToken
          bFOAccountID
          bFOContactID
          classLevel1
          companyCity
          companyCountryCode
          companyFederationId
          companyName
          companyStateOrProvinceCode
          companyStreet
          companyZipCode
          company_code
          # contactGoldenID
          contactId
          cookieEnabled
          countryCode
          countryDesc
          countryIsoCode
          # currency
          customer_code
          email
          emailOptIn
          enabled
          fedratedSearchToken
          firstName
          gcsWebAccess
          # headquarter
          # identityType
          isAILSet
          isEmailMatched
          # isInternal
          languageCode
          lastName
          level_code
          marketSegment
          middleName
          parentCompany
          region
          regionCode
          stateCode
          # trustLevel
          userAddress
          userCity
          # userContext
          userCountry
          userCountryCode
          userCountryDesc
          userCountryIsoCode
          userEntitlement
          userGroup
          userId
          userName
          userPhone
          userPostalCode
          userRegionCode
          userRegionDesc
          userState
          userStateCode
          userStateName
          user_code
          workPhone
          bfoApiUrl
          bfoErrorMsg
          bfoStatusCode
          idmsRefreshAccessToken
          bfoApiPayload
          UserGroupList
          federatedId
        }
      }
       `,
    });
    userLoginQuery.valueChanges.subscribe((result) => {
      let modifiedResult: any = {
        data: { getLoggedInUserInfo: {} }
      };
      modifiedResult.data.getLoggedInUserInfo = result.data.newGetLoggedInUserInfoSecure;
      if (!modifiedResult.data.getLoggedInUserInfo.accessToken) {
        modifiedResult.data.getLoggedInUserInfo.accessToken = '';
        modifiedResult.data.getLoggedInUserInfo.idmsFederatedId = '';
      }
      sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(modifiedResult));
      sessionStorage.setItem(SessionVariables.sessionToken, result.data.newGetLoggedInUserInfoSecure.JWT);
      sessionStorage.setItem(SessionVariables.isLoggedIn, '1');
      sessionStorage.setItem(SessionVariables.isUserLoggedIn, '1');
      this.http.get(environment.ipify).subscribe((res: any) => {
        sessionStorage.setItem(SessionVariables.ip, res.ip);
      });
      this.service.loginData.next(true);
    });
  }

  // old version
  // public getUserDetails(param: string): void {
  //   sessionStorage.setItem(SessionVariables.isUserLoggedIn, '0');
  //   const codeParam = `${param}`;
  //   let issValue: string | boolean = sessionStorage.getItem(SessionVariables.iss);
  //   issValue = issValue != "" ? true : false;
  //   let userLoginQuery: QueryRef<any>;
  //   let ip = sessionStorage.getItem(SessionVariables.ip);
  //   ip = ip ? ip : '';
  //   userLoginQuery = this.apollo.watchQuery({
  //     query: gql`query MyQuery {
  //       getLoggedInUserInfoSecure(input: {
  //         code: "${codeParam}",
  //         ipAddress: "${ip}",
  //         ISS: ${issValue}
  //       }) {
  //         CFID
  //         CFIDList
  //         Isparentaccess
  //         JWT
  //         JWTRefreshToken
  //         accessToken
  //         accountId
  //         ail
  //         ailApplications
  //         ailPrograms
  //         annualRevenue
  //         apigeeAccessToken
  //         bFOAccountID
  //         bFOContactID
  //         classLevel1
  //         companyCity
  //         companyCountryCode
  //         companyFederationId
  //         companyName
  //         companyStateOrProvinceCode
  //         companyStreet
  //         companyZipCode
  //         company_code
  //         contactGoldenID
  //         contactId
  //         cookieEnabled
  //         countryCode
  //         countryDesc
  //         countryIsoCode
  //         currency
  //         customer_code
  //         email
  //         emailOptIn
  //         enabled
  //         fedratedSearchToken
  //         firstName
  //         gcsWebAccess
  //         headquarter
  //         identityType
  //         isAILSet
  //         isEmailMatched
  //         isInternal
  //         languageCode
  //         lastName
  //         level_code
  //         marketSegment
  //         middleName
  //         parentCompany
  //         region
  //         regionCode
  //         stateCode
  //         trustLevel
  //         userAddress
  //         userCity
  //         userContext
  //         userCountry
  //         userCountryCode
  //         userCountryDesc
  //         userCountryIsoCode
  //         userEntitlement
  //         userGroup
  //         userId
  //         userName
  //         userPhone
  //         userPostalCode
  //         userRegionCode
  //         userRegionDesc
  //         userState
  //         userStateCode
  //         userStateName
  //         user_code
  //         workPhone
  //         bfoApiUrl
  //         bfoErrorMsg
  //         bfoStatusCode
  //         idmsRefreshAccessToken
  //         bfoApiPayload
  //         UserGroupList
  //         federatedId
  //       }
  //     }
  //      `,
  //   });
  //   userLoginQuery.valueChanges.subscribe((result) => {
  //     let modifiedResult: any = {
  //       data: { getLoggedInUserInfo: {} }
  //     };
  //     modifiedResult.data.getLoggedInUserInfo = result.data.getLoggedInUserInfoSecure;
  //     if (!modifiedResult.data.getLoggedInUserInfo.accessToken) {
  //       modifiedResult.data.getLoggedInUserInfo.accessToken = '';
  //       modifiedResult.data.getLoggedInUserInfo.idmsFederatedId = '';
  //     }
  //     sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(modifiedResult));
  //     sessionStorage.setItem(SessionVariables.sessionToken, result.data.getLoggedInUserInfoSecure.JWT);
  //     sessionStorage.setItem(SessionVariables.isLoggedIn, '1');
  //     sessionStorage.setItem(SessionVariables.isUserLoggedIn, '1');
  //     this.http.get(environment.ipify).subscribe((res: any) => {
  //       sessionStorage.setItem(SessionVariables.ip, res.ip);
  //     });
  //     this.service.loginData.next(true);
  //   });
  // }



  public setCookieSettings(settingValue: number): any {

    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = screenIdsForTelemetry['home-overview'].screenId;
    this.apollo.mutate({
      mutation: gql`
      mutation{
        setCookiesSecure(input:{
          value:${settingValue},
          JWT:"${sessionToken}",
          screenId:"${screenId}",
          ipAddress:"${ip}"
        }){
          msg
        }
      }
    `,
    }).subscribe(data => {
      sessionStorage.setItem(SessionVariables.cookieEnabled, settingValue.toString());
    },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.setCookieSettings(settingValue);
              }
            })
        }
      });
  }

}
