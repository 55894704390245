import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, ErrorCodes } from '../../constants';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';

@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlCaseTypeServiceSecure {

  constructor(
    private apollo: Apollo,
    public gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure
  ) { }

  public getCaseTypesData(): void {
    let caseTypesQuery: QueryRef<any>;
    let caseTypes: string[] = []; // Graph QL returns case type 'All', so don't have to add it here.
    // public caseTypes: any;
    const caseStatus: string[] = ['All']; // since the graph QL does not return 'All' status, you add it here.
    const companyDropDownList: string[] = [];
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    caseTypesQuery = this.apollo.watchQuery({
      query: gql`
      query caseTypesQuery {
        getFilterDetails(
          input: { idmsFederatedId: "704a1000-7517-4d8a-ba89-0bb5538ad86c" }
        ) {
          status {
            caseType
            status
          }
          caseType
          company {
            SEAccountID
            company
            customer_code
          }
        }
      }
    `,
    });

    caseTypesQuery.valueChanges.subscribe(
      (result) => {
        sessionStorage.setItem(SessionVariables.caseTypes, JSON.stringify(result.data.getFilterDetails));
        caseTypes = result.data.getFilterDetails.caseType;
        for (let i = 0; i < result.data.getFilterDetails.status.length; i++) {
          if (caseStatus.indexOf(result.data.getFilterDetails.status[i]['status'].trim()) === -1) { // store unique status trimmed
            caseStatus.push(result.data.getFilterDetails.status[i]['status'].trim());
          }
          companyDropDownList.push(result.data.getFilterDetails.company[i].company.trim());
        }
        sessionStorage.setItem(SessionVariables.caseTypes, JSON.stringify(caseTypes));
        sessionStorage.setItem(SessionVariables.caseStatus, JSON.stringify(caseStatus));
        sessionStorage.setItem(SessionVariables.companyDropDownList, JSON.stringify(companyDropDownList));
      },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.getCaseTypesData();
              }
            })
        }
      }
    );
  }

}
