import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, ErrorCodes } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';
@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlSiteEquipmentDetailsServiceSecure {

  constructor(
    private apollo: Apollo,
    private service: DynamicComponentCreateService,
    private gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure) { }

  public getSiteEquipments(siteId:any):void {
    
        let siteDetailsQuery: QueryRef<any>;
        let ip = sessionStorage.getItem(SessionVariables.ip);
        ip = ip ? ip : '';
        const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
        let screenId = '';
        siteDetailsQuery = this.apollo.watchQuery({
        query: gql`query{
            getSiteEquipmentDetails(input:{
            siteId:"${siteId}",
            JWT: "${sessionToken}",
            screenId: "${screenId}",
            ipAddress: "${ip}"
            }){
                CustomerName
                SiteName
                SiteAlphaCode
                IAEnabled
                IAVersion
                IAFerretVersion
                IAFerretStatus
                IAInstallDate
                IAFerretDateRun
                IAApplicationType
                IAFerretModifiedDate
                IAHostStation
                IAName
                IALocation
                IAProcessUnit
                IAServiceRep
                IAFoxwatchCode
                TRICEnabled
                TRICSystemVersion
                TRICInstallDate
                TRICFerretStatus
                TRICFerretDateRun
                TRICFerretModifiedDate
                TRICSystemChasis
                TRICDcsDesc
                TRICApplicationType
                TRIC_PLC_SW_Version
                Equipments {
                    EquipmentDesc
                    EquipmentID
                    EquipmentLifeCycleColor
                    EquipmentShortDesc
                    IALifeCycleID
                    InstallStatus
                    LifeCycleGroupDesc
                    LifeCycleGroupID
                    Part_Number
                    Product_Line_Cd
                    SiteCode
                    SiteName
                    num
                    }

            }
        }
        `,
        });
        
        siteDetailsQuery.valueChanges.subscribe((result) => {
        const resultData = result && result.data;
        const siteEquipmentDetails = resultData && resultData.getSiteEquipmentDetails;
        sessionStorage.setItem(SessionVariables.siteEquipmentDetails, JSON.stringify(siteEquipmentDetails));
        this.service.getSiteDetails.next(true);

        //! Testing
          // console.log(siteEquipmentDetails);
          // console.log(JSON.stringify(siteEquipmentDetails));
          // console.log('--------- SessionVariables -------------');
          // console.log(sessionStorage.getItem(SessionVariables.siteEquipmentDetails));
          // console.log(this.service.getSiteDetails.getValue());
        //! End Testing
        
        },
        (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
            //code to refresh token and to call again
            this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
                const data = result.data.renewToken;
                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
                } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.getSiteEquipments(siteId);
                }
            })
        }
        });
  }
}
