import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, screenIdsForTelemetry, ErrorCodes } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { Router } from '@angular/router';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { GcsGraphQlsetbBfoLogsServiceSecure } from './gcs.graph-ql.gcs-set-bfoLogs.service.secure';

@Injectable({
  providedIn: 'root'
})
export class GcsGraphQlCreateNewCaseServiceSecure {
  stateCountryCode: any;
  blob: Blob;

  constructor(
    private apollo: Apollo,
    public service: DynamicComponentCreateService,
    public router: Router,
    public gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure,
    private httpClient: HttpClient,
    private gcsGraphQlsetbBfoLogsServiceSecure: GcsGraphQlsetbBfoLogsServiceSecure
  ) { }

  public getCaseTypesDropdownList(): any {
    let caseTypeQuery: QueryRef<any>;
    caseTypeQuery = this.apollo.watchQuery({
      query: gql`
      query{
        listCaseTypes{
          items {
            caseTypeCode
            caseTypeName
            caseDisplayOrder
          }
        }
      }
    `,
    });
    caseTypeQuery.valueChanges.subscribe((result) => {
      sessionStorage.setItem(SessionVariables.getCaseTypes, JSON.stringify(result.data.listCaseTypes.items));
      this.service.getCaseTypes.next(true);
    });
  }

  public getPriorities(caseType: string): any {
    let priorityQuery: QueryRef<any>;
    priorityQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getPriorityListForCreateCase(input:{caseTypeCode:"${caseType}"}){
         priorityListForCreateCase{
          impactCode
          impactDesc
        }
        }
      }
    `,
    });
    priorityQuery.valueChanges.subscribe((result) => {
      sessionStorage.setItem(SessionVariables.getPriorities,
        JSON.stringify(result.data.getPriorityListForCreateCase.priorityListForCreateCase));
      this.service.getPriorities.next(true);
    });
  }

  public getCompanies(userCode: number, caseTypeCode: string): any {
    debugger
    try {
      let companyQuery: QueryRef<any>;
      let ip = sessionStorage.getItem(SessionVariables.ip);
      ip = ip ? ip : '';
      const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
      let screenId = '';
      companyQuery = this.apollo.watchQuery({
        query: gql`
        query{
          getCompanyListForCreateCaseSecure(input:{
            JWT: "${sessionToken}",
            screenId: "${screenId}",
            ipAddress: "${ip}",
            caseTypeCode:"${caseTypeCode}",
            user_code:${userCode}}){
            companyListForCreateCase{
              partnerCode
              localCompany
              localCompanyCode
            }
          }
        }
      `,
        fetchPolicy: 'network-only'
      });
      companyQuery.valueChanges.subscribe((result) => {
        this.service.WritelogToCloudWatch(result)
        sessionStorage.setItem(SessionVariables.getCompanies,
          JSON.stringify(result.data.getCompanyListForCreateCaseSecure.companyListForCreateCase));
        this.service.getCompanies.next(true);
      },
        (err) => {
          this.service.WritelogToCloudWatch(err)
          const errorMessage = err.message;
          if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
            //code to refresh token and to call again
            this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
              (result) => {
                const data = result.data.renewToken;
                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                  // probably should logout the user
                } else if (data.JWT) {
                  sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                  const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                  updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                  updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                  updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                  updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                  sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                  this.getCompanies(userCode, caseTypeCode);
                }
              })
          }
        });

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }

  public getSites(caseTypeCode: string, userCode: number, localCompanyCode: number): any {
    try {
      let sitesQuery: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    sitesQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getSiteListForCreateCaseSecure(input:{
          caseTypeCode:"${caseTypeCode}",
          localCompanyCode:${localCompanyCode},
          JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}",
          user_code:${userCode}}){
         siteListForCreateCase{
          siteGuid
          siteName
          productFamily
        }
        }
      }
    `,
      fetchPolicy: 'network-only'
    });
    sitesQuery.valueChanges.subscribe((result) => {
      this.service.WritelogToCloudWatch(result)
      sessionStorage.setItem(SessionVariables.getSites,
        JSON.stringify(result.data.getSiteListForCreateCaseSecure.siteListForCreateCase));
      this.service.getSites.next(true);
    },
      (err) => {
        this.service.WritelogToCloudWatch(err)
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.getSites(caseTypeCode, userCode, localCompanyCode);
              }
            })
        }
      });

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }

  public getContacts(partnerCode: string, caseTypeCode: string): any {
    try {
      let contactsQuery: QueryRef<any>;
    contactsQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getContactListForCreateCase(input:{partnerCode:"${partnerCode}"caseTypeCode:"${caseTypeCode}"}){
          contactListForCreateCase{
            phone
            firstName
            lastName
            email
            customerContactNumber
          }
        }
      }
    `,
      fetchPolicy: 'network-only'
    });
    contactsQuery.valueChanges.subscribe((result) => {
      sessionStorage.setItem(SessionVariables.getContacts,
        JSON.stringify(result.data.getContactListForCreateCase.contactListForCreateCase));
      this.service.getContacts.next(true);
    },(err)=>{
      this.service.WritelogToCloudWatch(err)

    });

    } catch (error) {

      this.service.WritelogToCloudWatch(error)

    }

  }

  public getProductFamilies(caseTypeCode: string): any {
    try {
      let productFamilyQuery: QueryRef<any>;
    productFamilyQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getProductInfoForCreateCase(input:{caseTypeCode:"${caseTypeCode}"}){
          productBrandList{
            brandName
            brandCode
            brandDesc
          }
        }
      }
    `,
    });
    productFamilyQuery.valueChanges.subscribe((result) => {
      this.service.WritelogToCloudWatch(result)
      sessionStorage.setItem(SessionVariables.getProductFamilyList,
        JSON.stringify(result.data.getProductInfoForCreateCase.productBrandList));
      this.service.getProductFamilies.next(true);
    },(err)=>{  this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }
  public getProductGroups(caseTypeCode: string, brandCode: number): any {

    try {
      let productGroupQuery: QueryRef<any>;
      productGroupQuery = this.apollo.watchQuery({
        query: gql`
        query{
          getProductInfoForCreateCase(input:{caseTypeCode:"${caseTypeCode}",brandCode:${brandCode}}){
            productBrandGroupsList{
             brandGroupName
             brandGroupCode
             brandGroupDesc
           }
          }
        }
      `,
      });
      productGroupQuery.valueChanges.subscribe((result) => {
        this.service.WritelogToCloudWatch(result)
        sessionStorage.setItem(SessionVariables.getProductGroupList,
          JSON.stringify(result.data.getProductInfoForCreateCase.productBrandGroupsList));
        this.service.getProductGroups.next(true);
      },(err)=>{ this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }
  public getProduct(caseTypeCode: string, brandGroupCode: number): any {

    try {
      let productQuery: QueryRef<any>;
      productQuery = this.apollo.watchQuery({
        query: gql`
        query{
          getProductInfoForCreateCase(input:{caseTypeCode:"${caseTypeCode}",brandGroupCode:${brandGroupCode}}){
            productBrandProductsList{
              brandProductName
              brandProductCode
              brandProductDesc
              prodTypeCode
           }
          }
        }
      `,
      });
      productQuery.valueChanges.subscribe((result) => {
        this.service.WritelogToCloudWatch(result)
        sessionStorage.setItem(SessionVariables.getProduct,
          JSON.stringify(result.data.getProductInfoForCreateCase.productBrandProductsList));
        this.service.getProduct.next(true);
      },(err)=>{ this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)
    }

  }
  public getProductVersion(caseTypeCode: string, prodTypeCode: number): any {
    try {
      let productVersionQuery: QueryRef<any>;
    productVersionQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getProductInfoForCreateCase(input:{caseTypeCode:"${caseTypeCode}",brandProductCode:${prodTypeCode}}){
          productBrandVersionsList{
            versionCode
            versionName
            versionDesc
            commercialReference
         }
        }
      }
    `,
    });
    productVersionQuery.valueChanges.subscribe((result) => {
      this.service.WritelogToCloudWatch(result)
      sessionStorage.setItem(SessionVariables.getProductVersion,
        JSON.stringify(result.data.getProductInfoForCreateCase.productBrandVersionsList));
      this.service.getProductVersion.next(true);
    },(err)=>{this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }
  public getRequestInfo(caseTypeCode: string): any {
    try {
      let RequestInfoQuery: QueryRef<any>;
    RequestInfoQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getRequestInfoForCreateCase(input:{caseTypeCode:"${caseTypeCode}"}){
          caseAreaList{
            sourceCode
            sourceDesc
          }
          caseReprodList{
            reprodCode
            reprodDesc
          }
        }
      }
    `,
    });
    RequestInfoQuery.valueChanges.subscribe((result) => {

      this.service.WritelogToCloudWatch(result)
      sessionStorage.setItem(SessionVariables.requestInfoAreaList,
        JSON.stringify(result.data.getRequestInfoForCreateCase.caseAreaList));
      sessionStorage.setItem(SessionVariables.requestInfoReprodList,
        JSON.stringify(result.data.getRequestInfoForCreateCase.caseReprodList));
      this.service.getRequestInfo.next(true);
    },(err)=>{this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }

  // get Request type for MEP case type
  public getRequestType(): any {
    try {
      let RequestInfoQuery: QueryRef<any>;
    RequestInfoQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getRequestType(id:"GCS"){
          id
          requestTypeList
        }
      }
    `,
    });
    RequestInfoQuery.valueChanges.subscribe((result) => {
      this.service.WritelogToCloudWatch(result)
      const resultData = result && result.data;
      const getRequestType = resultData && resultData.getRequestType;
      const requestArrayValues = getRequestType.requestTypeList || [];
      const makeRequestTypeArray = [];
      requestArrayValues.forEach(element => {
        const obj = {
          requestTypeName: element,
          requestTypeValue: element
        };
        makeRequestTypeArray.push(obj);
      });
      sessionStorage.setItem(SessionVariables.mepRequestTypeValues, JSON.stringify(makeRequestTypeArray));
      this.service.getRequestTypeValues.next(true);
    },(err)=>{this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }

  // MEP Failure Type Dropdown
  public getFailureType(): void {
    try {
      let RequestInfoQuery: QueryRef<any>;
      let ip = sessionStorage.getItem(SessionVariables.ip);
      ip = ip ? ip : '';
      const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
      let screenId = '';
    RequestInfoQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getFailureType(input:{
          JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}"
          }){
          failureTypeList{
            failureTypeCode
            failureTypeDesc
            sourceCode
            statusCode
            clientCode
          }
        }
      }
    `,
    });
    RequestInfoQuery.valueChanges.subscribe((result) => {
      this.service.WritelogToCloudWatch(result)
      const resultData = result && result.data;
      const getFailureType = resultData && resultData.getFailureType;
      const failureArrayValues = getFailureType.failureTypeList || [];

      sessionStorage.setItem(SessionVariables.mepFailureTypeValues, JSON.stringify(failureArrayValues));
      this.service.getFailureTypeValues.next(true);
    },(err)=>{ this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }

  // shipTo//
  public getShipTo(partnerCode: string): any {
    try {
      const getShipQL = JSON.parse(sessionStorage.getItem(SessionVariables.getCompanies));
    let priorityQuery: QueryRef<any>;
    priorityQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getBillToAndShipToDetails(input:{
          partnerNumber:"${getShipQL[0].partnerCode}",
          partnerType:"SH"
        }){
          billToAndShipToDetails{
            partnerFunction
            partnerDesc
            customerNumber
            companyName
            address1
            address2
            city
            state
            country
            zip
            partnerNumber
            partnerCounter
          }
        }
      }
    `,
    });
    priorityQuery.valueChanges.subscribe((result) => {
      this.service.WritelogToCloudWatch(result)
      sessionStorage.setItem(SessionVariables.getShiptToValues,
        JSON.stringify(result.data.getBillToAndShipToDetails.billToAndShipToDetails));
      this.service.getShipTo.next(true);
    },(err)=>{ this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }

  public getTECPERDetails(accessToken, userCode, bfoContactId, bfoAccountId): any {
    try {
      debugger;
      let TECPERQuery: QueryRef<any>;
      let ip = sessionStorage.getItem(SessionVariables.ip);
      ip = ip ? ip : '';
      const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
      const screenId = screenIdsForTelemetry['case-management-create-case'].screenId;
      TECPERQuery = this.apollo.watchQuery({
        query: gql`
      query{
        getAllBFOAccountDetails(input:{
          bFOContactID:"${bfoContactId}",
          bFOAccountID:"${bfoAccountId}",
          JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}"
        }){
          bfoApiUrl
          bfoErrorMsg
          bfoStatusCode
          allBFOAccountDetails{
            paAccountSEAccountId
            paAccountRole
            paAccountName
            paAccountId
            paSites{
              paSiteName
              paSiteId
              paSiteGuid
              paSiteActive
            }
            paContacts{
              paContactName
              paContactId
              paContactCvcpId
              paContactCfid
              email #backend changies should be deployed first then it will work else comment
            }
          }
        }
      }
    `,
      });
      TECPERQuery.valueChanges.subscribe(
        (result) => {
          this.service.WritelogToCloudWatch(result)
        //   var result1={
        //     "data":{
        //        "getAllBFOAccountDetails":{
        //           "bfoApiUrl":"https://api.se.com/v1/customer-support/partner-case/filters",
        //           "bfoErrorMsg":null,
        //           "bfoStatusCode":"200",
        //           "allBFOAccountDetails":[
        //              {
        //                 "paAccountSEAccountId":"0019473855",
        //                 "paAccountRole":"Direct",
        //                 "paAccountName":"Fauji Fertilizer Company Limited (F (Rawalpindi)",
        //                 "paAccountId":"001A000000rQFQfIAO",
        //                 "paSites":[
        //                    {
        //                       "paSiteName":"FFC-GM-Plant 1",
        //                       "paSiteId":"aHd12000000Kyx5CAC",
        //                       "paSiteGuid":"{89D8F1F2-7D14-4E19-B028-04D4E48BD48C}",
        //                       "paSiteActive":"true",
        //                       "__typename":"paSites"
        //                    },
        //                    {
        //                       "paSiteName":"FFC-GM-Plant 2",
        //                       "paSiteId":"aHd1H0000008Q9eSAE",
        //                       "paSiteGuid":"{734C0413-9D60-4DAD-88AB-6806A779F0E9}",
        //                       "paSiteActive":"true",
        //                       "__typename":"paSites"
        //                    }
        //                 ],
        //                 "paContacts":[
        //                    {
        //                       "paContactName":"Umar Saeed",
        //                       "paContactId":"0031H00002QsbQZQAZ",
        //                       "paContactCvcpId":"a3o1H000000w6akQAA",
        //                       "paContactCfid":"168850",
        //                       "email":"",
        //                       "__typename":"paContacts"
        //                    },
        //                    {
        //                       "paContactName":"Muazzam Khan",
        //                       "paContactId":"0031200001iUujsAAC",
        //                       "paContactCvcpId":"a3o120000009SH8AAM",
        //                       "paContactCfid":"108919",
        //                       "email":"",
        //                       "__typename":"paContacts"
        //                    },
        //                    {
        //                       "paContactName":"Waqar Azeem",
        //                       "paContactId":"0031200001iUujtAAC",
        //                       "paContactCvcpId":"a3o120000009SFZAA2",
        //                       "paContactCfid":"117888",
        //                       "email":"",
        //                       "__typename":"paContacts"
        //                    },
        //                    {
        //                       "paContactName":"Samran Wadood",
        //                       "paContactId":"0031200001iUujuAAC",
        //                       "paContactCvcpId":"a3o120000009SBXAA2",
        //                       "paContactCfid":"106589",
        //                       "email":"",
        //                       "__typename":"paContacts"
        //                    },
        //                    {
        //                       "paContactName":"Usama Khan",
        //                       "paContactId":"0031200001iUujvAAC",
        //                       "paContactCvcpId":"a3o120000009SN3AAM",
        //                       "paContactCfid":"116067",
        //                       "email":"",
        //                       "__typename":"paContacts"
        //                    },
        //                    {
        //                       "paContactName":"Qaisar Iqbal",
        //                       "paContactId":"0031200001iUujxAAC",
        //                       "paContactCvcpId":"a3o120000009S2rAAE",
        //                       "paContactCfid":"168846",
        //                       "email":"",
        //                       "__typename":"paContacts"
        //                    },
        //                    {
        //                       "paContactName":"Rehan Asif",
        //                       "paContactId":"0031200001iUujyAAC",
        //                       "paContactCvcpId":"a3o120000009SHkAAM",
        //                       "paContactCfid":"117240",
        //                       "email":"",
        //                       "__typename":"paContacts"
        //                    },
        //                    {
        //                       "paContactName":"Ali Mujahid",
        //                       "paContactId":"0031200001jnn13AAA",
        //                       "paContactCvcpId":"a3o120000009e5WAAQ",
        //                       "paContactCfid":"128853",
        //                       "email":"",
        //                       "__typename":"paContacts"
        //                    },
        //                    {
        //                       "paContactName":"Ussama Aman Khan",
        //                       "paContactId":"0031H00002SZ5DDQA1",
        //                       "paContactCvcpId":"a3o1H000001acGBQAY",
        //                       "paContactCfid":"168847",
        //                       "email":"",
        //                       "__typename":"paContacts"
        //                    }
        //                 ],
        //                 "__typename":"allBFOAccountDetails"
        //              }
        //           ],
        //           "__typename":"allBFOAccountDetailsOutput"
        //        }
        //     }
        //  }





          sessionStorage.setItem(SessionVariables.getTECPERData,JSON.stringify(result.data.getAllBFOAccountDetails.allBFOAccountDetails));
          sessionStorage.setItem(SessionVariables.getTECPERDat, JSON.stringify(result.data.getAllBFOAccountDetails));
          this.service.getTECPERInfo.next(true);
        },
        (err) => {
          this.service.WritelogToCloudWatch(err)
          const errorMessage = err.message;
          if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
            //code to refresh token and to call again
            this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
              (result) => {
                const data = result.data.renewToken;
                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                  // probably should logout the user
                } else if (data.JWT) {
                  sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                  const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                  updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                  updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                  updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                  updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                  sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                  this.getTECPERDetails(accessToken, userCode, bfoContactId, bfoAccountId);
                }
              })
          }
        }
      );

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }
  // shipTo//

  // billTo//
  public getBillTo(partnerCode: string): any {
    try {
      const getShipQL = JSON.parse(sessionStorage.getItem(SessionVariables.getCompanies));
    let priorityQueryValue: QueryRef<any>;
    priorityQueryValue = this.apollo.watchQuery({
      query: gql`
    query{
      getBillToAndShipToDetails(input:{
        partnerNumber:"${getShipQL[0].partnerCode}",
        partnerType:"BP"
      }){
        billToAndShipToDetails{
          partnerFunction
          partnerDesc
          customerNumber
          companyName
          address1
          address2
          city
          state
          country
          zip
          partnerNumber
          partnerCounter
        }
      }
    }
  `,
    });
    priorityQueryValue.valueChanges.subscribe((result) => {
      this.service.WritelogToCloudWatch(result)
      sessionStorage.setItem(SessionVariables.getBillToValues,
        JSON.stringify(result.data.getBillToAndShipToDetails.billToAndShipToDetails));
      this.service.getBillTo.next(true);
    },(err)=>{ this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }


  // Get file//
  public getFileTo(): any {
    try {
      const loggedUser = this.service.getIds();
    const caseId = JSON.parse(sessionStorage.getItem(SessionVariables.TecOrPerSubmitCaseData));
    const homeCaseNumber = JSON.parse(sessionStorage.getItem(SessionVariables.tecSummaryCaseData)) || null;
    const caseNumberBoth = sessionStorage.getItem(SessionVariables.homeCaseId) || caseId.caseId;
    let fileQueryValue: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = screenIdsForTelemetry['case-summary'].screenId;
    fileQueryValue = this.apollo.watchQuery({
      query: gql`
    query{
      bfoGetFiles(input:{
        caseId:"${caseNumberBoth}"
        screenId: "${screenId}",
        JWT: "${sessionToken}",
        ipAddress: "${ip}"
      }){
        totalSize
        bfoApiPayload
        bfoApiUrl
        bfoErrorMsg
        bfoGetFileList{
          lastModifiedDate
          fileType
          fileTitle
          fileId
          fileExtension
          fileDescription
          CreatedByName
          CreatedById
          contentSize
          contentDocumentId
        }
        bfoStatusCode
      }
    }
  `,
      fetchPolicy: 'network-only'
    });
    fileQueryValue.valueChanges.subscribe(
      (result) => {
        this.service.WritelogToCloudWatch(result)

        const getFileData = result && result.data;
        const fileData = getFileData && getFileData.bfoGetFiles;
        if (getFileData && getFileData.bfoGetFiles.bfoStatusCode == 200) {
          const getFileDataTo = getFileData && getFileData.bfoGetFiles && getFileData.bfoGetFiles.bfoGetFileList;
          sessionStorage.setItem(SessionVariables.getFileDataTo, JSON.stringify(getFileDataTo));
          this.service.getFileTo.next(true);
        } else {
          this.httpClient.get(environment.errorBfoJson).subscribe((data: Array<any>) => {

            let userMsg = JSON.parse(fileData.bfoErrorMsg) || {};
            const userCode = this.service.getIds();

            let errorDataList = data.filter((errorItem) => {
              if (errorItem.API_URL === fileData.bfoApiUrl
                && errorItem.API_Error_Code === userMsg.code
                && errorItem.HTTP_Code === fileData.bfoStatusCode) {
                return errorItem;
              }
            });
            if (errorDataList.length !== 0) {
              let errdetails = errorDataList[0];
              this.gcsGraphQlsetbBfoLogsServiceSecure.setBfoLog(userCode.user_code, "API Event", "GCSWeb2020", errdetails.HTTP_Code, errdetails.API_Error_Code,
                errdetails.API_URL, errdetails.API_Error_Type, errdetails.API_UserMessage, userMsg.developerMessage,
                (fileData.bfoApiPayload && fileData.bfoApiPayload.replace(/['"]+/g, '')));
            }
            else {
              this.gcsGraphQlsetbBfoLogsServiceSecure.setBfoLog(userCode.user_code, "API Event", "GCSWeb2020", fileData.bfoStatusCode, userMsg.code,
                fileData.bfoApiUrl, "", userMsg.userMessage, userMsg.developerMessage,
                (fileData.bfoApiPayload && fileData.bfoApiPayload.replace(/['"]+/g, '')));
            }
          })
        }
      },
      (err) => {
        this.service.WritelogToCloudWatch(err)
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.getFileTo();
              }
            })
        }
      }
    );

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }

  // Upload File//
  public getUploadTo(image: any): any {
    try {
      debugger;
      const loggedUser = this.service.getIds();
    const caseId = JSON.parse(sessionStorage.getItem(SessionVariables.TecOrPerSubmitCaseData));
    const homeCaseNumber = JSON.parse(sessionStorage.getItem(SessionVariables.tecSummaryCaseData)) || null;
    const caseNumberBoth = sessionStorage.getItem(SessionVariables.homeCaseId) || caseId.caseId;
    let formattedAttachments = '';
    const updateFileData = image;
    updateFileData.map(attachment => {
      formattedAttachments += `{ description: "${attachment.fileName}", pathOnClient: "${attachment.fileType}", versionData: "${attachment.src}" }`;
    });
    const formatedUploadedFile = `[${formattedAttachments}]`;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = screenIdsForTelemetry['case-summary'].screenId;
    const obj = `{
      networkId:"0DB12000000KyopGAC",
      caseId:"${caseNumberBoth}",
      firstPublishLocationId:"${caseNumberBoth}",
      fileDetails:[${formattedAttachments}],
      screenId: "${screenId}",
      JWT: "${sessionToken}",
      ipAddress: "${ip}"
    }`;
    // const getUploadQL = JSON.parse(sessionStorage.getItem(SessionVariables.getCompanies));
    this.apollo.mutate({
      mutation: gql`
    mutation{
      bfoPostFile(input:${obj}){
        bfoPostFileList{
          bfoApiPayload
          bfoApiUrl
          bfoErrorMsg
          bfoStatusCode
          id
          success
          errors
        }
      }
    }
  `,
    }).subscribe(
      (result) => {
        this.service.WritelogToCloudWatch(result)
        const getResult: any = result && result.data;
        const bfoPostFile = getResult && getResult.bfoPostFile && getResult.bfoPostFile.bfoPostFileList;
        if(bfoPostFile && (bfoPostFile[0].bfoStatusCode == 200 || bfoPostFile[0].bfoStatusCode == 201)) {
          const getIdValue = bfoPostFile && bfoPostFile;
          sessionStorage.setItem(SessionVariables.postFileData, JSON.stringify(getIdValue));
          this.service.getUploadTo.next(true);
        } else {
          this.httpClient.get(environment.errorBfoJson).subscribe((data: Array<any>) => {

            let userMsg = JSON.parse(bfoPostFile[0].bfoErrorMsg) || {};
            const userCode = this.service.getIds();

            let errorDataList = data.filter((errorItem) => {
              if (errorItem.API_URL === bfoPostFile[0].bfoApiUrl
                && errorItem.API_Error_Code === userMsg.code
                && errorItem.HTTP_Code === bfoPostFile[0].bfoStatusCode) {
                return errorItem;
              }
            });
            if (errorDataList.length !== 0) {
              let errdetails = errorDataList[0];
              this.gcsGraphQlsetbBfoLogsServiceSecure.setBfoLog(userCode.user_code, "API Event", "GCSWeb2020", errdetails.HTTP_Code, errdetails.API_Error_Code,
                errdetails.API_URL, errdetails.API_Error_Type, errdetails.API_UserMessage, userMsg.developerMessage,
                (bfoPostFile[0].bfoApiPayload && bfoPostFile[0].bfoApiPayload.replace(/['"]+/g, '')));
            }
            else {
              this.gcsGraphQlsetbBfoLogsServiceSecure.setBfoLog(userCode.user_code, "API Event", "GCSWeb2020", bfoPostFile[0].bfoStatusCode, userMsg.code,
                bfoPostFile[0].bfoApiUrl, "", userMsg.userMessage, userMsg.developerMessage,
                (bfoPostFile[0].bfoApiPayload && bfoPostFile[0].bfoApiPayload.replace(/['"]+/g, '')));
            }

          })
        }
      },
      (err) => {
        this.service.WritelogToCloudWatch(err)
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.getUploadTo(image);
              }
            })
        }
      }
    );

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }


  // Download File//
  public getDownloadTo(uid: string, title: any, filetype: any): any {
    try {
      const loggedUser = this.service.getIds();
    // const getDownloadQL = JSON.parse(sessionStorage.getItem(SessionVariables.getCompanies));
    let downloadQueryValue: QueryRef<any>;
    downloadQueryValue = this.apollo.watchQuery({
      query: gql`
     query{
      bfoDownloadFile(input:{
        accessToken:"${loggedUser.accessToken}",
        fileId:"${uid}"
      }){
        fileContent
      }
    }
   `,
    });
    downloadQueryValue.valueChanges.subscribe((result) => {
      this.service.WritelogToCloudWatch(result)
      const downloadBlob = result && result.data && result.data.bfoDownloadFile && result.data.bfoDownloadFile.fileContent;
      const downloadURL = window.URL.createObjectURL(new Blob([downloadBlob], { type: `${filetype}` }));
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = title + '.' + filetype;
      link.click();
      this.service.getDownloadTo.next(true);
    },(err)=>{this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)


    }

  }

  // Get Field Service Type Lists
  public getFieldServiceType(): any {
    try {
      let fieldServiceType: QueryRef<any>;
      fieldServiceType = this.apollo.watchQuery({
        query: gql`
        query{
          getFieldServiceTypes{
            fieldServiceTypes{
              fieldServiceId
              description
            }
          }
        }
      `,
      });
      fieldServiceType.valueChanges.subscribe((result) => {
        this.service.WritelogToCloudWatch(result)
        const resultData = result && result.data && result.data.getFieldServiceTypes;
        sessionStorage.setItem(SessionVariables.getOssFieldServiceTypes,
          JSON.stringify(resultData.fieldServiceTypes));
        this.service.getFieldServiceType.next(true);
      },(err)=>{this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }

  // Get Field Service Type Lists
  public getFieldServiceOss(): any {
    try {
      let fieldServiceSupportList: QueryRef<any>;
    fieldServiceSupportList = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getOSSSupportList(id: "GCS"){
          id
          services
        }
      } `,
    });
    fieldServiceSupportList.valueChanges.subscribe((result) => {
      this.service.WritelogToCloudWatch(result)
      const resultData = result && result.data && result.data.getOSSSupportList;
      const ossSupport = []
      resultData.services.forEach(element => {
        const obj = {
          description: element,
          fieldServiceId: element
        };
        ossSupport.push(obj);
      });
      sessionStorage.setItem(SessionVariables.getOSSSupportList,
        JSON.stringify(ossSupport));
      this.service.getFieldServiceOss.next(true);
    },(err)=>{this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }


  // Get Field Training Type Lists
  public getFieldServiceOssTraining(): any {
    try {
      let fieldServiceSupportList: QueryRef<any>;
    fieldServiceSupportList = this.apollo.watchQuery({
      query: gql`
      query{
        getOSSTrainingList(id:"GCS"){
          id
          trainings
        }
      }`,
    });
    fieldServiceSupportList.valueChanges.subscribe((result) => {
      this.service.WritelogToCloudWatch(result)
      const resultData = result && result.data && result.data.getOSSTrainingList;
      const ossTraining = [];
      resultData.trainings.forEach(element => {
        const obj = {
          description: element,
          fieldServiceId: element
        };
        ossTraining.push(obj);
      });
      sessionStorage.setItem(SessionVariables.getOSSTrainingList,
        JSON.stringify(ossTraining));
      this.service.getFieldServiceOssTraining.next(true);
    },(err)=>{this.service.WritelogToCloudWatch(err)});

    } catch (error) {
      this.service.WritelogToCloudWatch(error)

    }

  }
  // Get Field Location Type Lists
  public getFieldServiceOssLocation(): any {
    try {
      let fieldServiceLocationList: QueryRef<any>;
    fieldServiceLocationList = this.apollo.watchQuery({
      query: gql`
      query{
        getOSSTrainingLocationList(id:"GCS"){
          id
          locations
        }
      } `,
    });
    fieldServiceLocationList.valueChanges.subscribe((result) => {
      this.service.WritelogToCloudWatch(result)
      const resultData = result && result.data && result.data.getOSSTrainingLocationList;
      const ossLocation = [];
      resultData.locations.forEach(element => {
        const obj = {
          description: element,
          fieldServiceId: element
        };
        ossLocation.push(obj);
      });
      sessionStorage.setItem(SessionVariables.getOSSTrainingLocationList,
        JSON.stringify(ossLocation));
      this.service.getFieldServiceOssLocation.next(true);
    },(err)=>{ this.service.WritelogToCloudWatch(err)});

    } catch (error) { this.service.WritelogToCloudWatch(error)

    }
  }

  // Get Field Course Type Lists
  public getFieldServiceOssCourse(): any {
    try {
      let fieldServiceCourseList: QueryRef<any>;
    fieldServiceCourseList = this.apollo.watchQuery({
      query: gql`
      query{
        getOSSCourseList(id:"GCS"){
          id
          courses
        }
      }`,
    });
    fieldServiceCourseList.valueChanges.subscribe((result) => {
      this.service.WritelogToCloudWatch(result)
      const resultData = result && result.data && result.data.getOSSCourseList;
      const ossCourse = [];
      resultData.courses.forEach(element => {
        const obj = {
          description: element,
          fieldServiceId: element
        }
        ossCourse.push(obj);
      });
      sessionStorage.setItem(SessionVariables.getOSSCourseList,
        JSON.stringify(ossCourse));
      this.service.getFieldServiceOssCourse.next(true);
    },(err)=>{this.service.WritelogToCloudWatch(err)});

    } catch (error) {this.service.WritelogToCloudWatch(error)

    }

  }
}
