import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables } from '../constants';
import { DynamicComponentCreateService } from '../gcs.business.services/dynamic-component-create.service';

@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlHeaderNavChipsService {

  private getHeaderSubmenuQuery: QueryRef<any>;
  public myCompanySubmenus: any;

  constructor(private apollo: Apollo, private service: DynamicComponentCreateService) { }

  public getHeaderNav(menuId: any): any {
    let HeaderQuery: QueryRef<any>;
    // get the fed Id and stick in inside line 22 deferated
    HeaderQuery = this.apollo.watchQuery({
      query: gql`query{
          getHeaderLink(id: "${menuId}" ) {
            menuList {
              chipName
              subMenu
            }
          }
        }
        `,
    });
    HeaderQuery.valueChanges.subscribe((result) => {
      const headerInfo = result.data.getHeaderLink.menuList;
      const resultArray = headerInfo.map((a) => a.chipName);
      sessionStorage.setItem(SessionVariables.headerNavChips, JSON.stringify(resultArray));
      this.service.headerNavChips.next(true);
    });
  }

  public getHeaderSubMenuList(menuId): void {
    this.getHeaderSubmenuQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getHeaderLink(id: "${menuId}") {
          menuList {
            chipName
            subMenu
          }
        }
    }`,
    });
    this.getHeaderSubmenuQuery.valueChanges.subscribe((result) => {
      if (result) {
        const data = result.data || {};
        const getMenuList = (data.getHeaderLink && data.getHeaderLink.menuList) || [];
        sessionStorage.setItem(SessionVariables.allSubMenus, JSON.stringify(getMenuList));
        this.service.allSubMenus.next(true);
      }
    });
  }

  public getBannerMessage(): void {
    let bannerQuery: QueryRef<any>;
    bannerQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getGcsBannerAlertMsg{
          alertMsg
        }
    }`,
    });
    bannerQuery.valueChanges.subscribe(result => {
      sessionStorage.setItem(SessionVariables.getbannerMessage, JSON.stringify(result.data.getGcsBannerAlertMsg));
      this.service.getBannerMessage.next(true);
    },
    (error: any) => {
      sessionStorage.setItem(SessionVariables.getbannerMessage, JSON.stringify({alertMsg: 'The Process Automation Global Customer Support website is temporarily unavailable, please try again later.'}));
      this.service.getBannerMessage.next(true);
    });
    // return JSON.parse(sessionStorage.getItem(SessionVariables.getbannerMessage));
  }

}
