import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { SeWebModule } from '@se/web-ui-angular';
import { AppComponent } from './app.component';
import { AuthGuard } from './gcs-common-shared/gcs-auth-guard/auth.guard';
import { LandingComponent } from './landing/landing.component';
// import { CaseSummaryComponent } from './gcs.modules/gcs-case-management/case-summary/case-summary.component';
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'home', loadChildren: () => import('./gcs-login/gcs-login.module').then(m => m.GcsLoginModule) },
  { path: 'gcsweb',
  loadChildren: () => import('./gcs.components/gcs.components.module').then(m => m.GcsDashboardModule), canActivate: [AuthGuard] },
  { path: 'my-company-module', loadChildren: () =>
   import('./gcs.modules/gcs-mycompany/gcs-mycompany.module').then(m => m.GcsMycompanyModule) },
  { path: 'my-cm-module', loadChildren: () =>
   import('./gcs.modules/gcs-case-management/gcs-case-management.module').then(m => m.GcsCaseManagementModule) },
  { path: 'redirectToNewGCS/:pageId', component: LandingComponent},
  //{ path: 'case/:caseId', redirectTo: 'my-cm-module'},
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
}), SeWebModule.forRoot()],
  exports: [RouterModule],
  bootstrap: [AppComponent],
})
export class AppRoutingModule {}
