import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, ErrorCodes } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';
@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlRemoteWatchDetailsServiceSecure {

  constructor(
    private apollo: Apollo,
    private service: DynamicComponentCreateService,
    private gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure) { }

  public getCustomerSiteInfo(siteId: any): void {
    let customerSiteInfoQuery: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    customerSiteInfoQuery = this.apollo.watchQuery({
      query: gql`query{
        getRemoteServicesCustomerSiteInfo(input:{
          JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}",
          siteId: "${siteId}"
        }){
            ParentCompany
            Company
            Address
            PostalCode
            City
            Country
            Region
            Site
            AlphaCode
            RemoteServicesDesc
            IARPVersionDesc
            IA_Enabled
            Tric_Enabled
            IAVersionDesc
            tricVersionDesc
            IAFerretDaterun
            TricFerretDaterun
            Comments

         }
       }
      `,
    });

    customerSiteInfoQuery.valueChanges.subscribe((result) => {
      const resultData = result && result.data;
      const customerSiteInfo = resultData && resultData.getRemoteServicesCustomerSiteInfo;
      sessionStorage.setItem(SessionVariables.customerSiteInfo, JSON.stringify(customerSiteInfo));
      this.service.getCustomerSiteInfo.next(true);
      },
      (err) => {
      const errorMessage = err.message;
      if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
        //code to refresh token and to call again
        this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
          (result) => {
            const data = result.data.renewToken;
            if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
              // probably should logout the user
            } else if (data.JWT) {
              sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
              const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
              updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
              updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
              updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
              updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
              sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
              this.getCustomerSiteInfo(siteId);
            }
          })
      }
    });
  }

  public getRemoteServicesEmails(siteId: any): void {
    let remoteServicesEmailsQuery: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    remoteServicesEmailsQuery = this.apollo.watchQuery({
      query: gql`query{
        getRemoteServicesEmails(input:{
          siteId:"${siteId}",
          JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}"
        }){
          usersToBeInformed {
            user_code
            user_email
            LastName
            FirstName
            LocalCompany
            LevelCode

          }
         }
       }
      `,
    });

    remoteServicesEmailsQuery.valueChanges.subscribe((result) => {
      const resultData = result && result.data;
      const remoteServicesEmails = resultData && resultData.getRemoteServicesEmails;
      sessionStorage.setItem(SessionVariables.remoteServicesEmails, JSON.stringify(remoteServicesEmails));
      this.service.getRemoteServicesEmails.next(true);
      },
      (err) => {
      const errorMessage = err.message;
      if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
        //code to refresh token and to call again
        this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
          (result) => {
            const data = result.data.renewToken;
            if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
              // probably should logout the user
            } else if (data.JWT) {
              sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
              const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
              updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
              updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
              updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
              updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
              sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
              this.getRemoteServicesEmails(siteId);
            }
          })
      }
    });
  }

  public getSiteDetails(siteId: any): void {
    let siteDetailsQuery: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    siteDetailsQuery = this.apollo.watchQuery({
      query: gql`query{
        getRemoteServicesSiteDetail(input:{
          siteId:"${siteId}",
          JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}"
        }){
            SiteType
            Status
            Procedure
            EventReports
            RemoteServicesCode
            IAFerretStatusDesc
            Region_Desc
            Country_Desc
            company
            RwsServiceProviderDesc
            CustContact1_FirstName
            CustContact1_LastName
            CustContact1_Phone
            CustContact1_Email
            CustContact2_FirstName
            CustContact2_LastName
            CustContact2_Phone
            CustContact2_Email
            CustITContact_FirstName
            CustITContact_LastName
            CustITContact_Phone
            CustITContact_Email
            InvensysFSR_FirstName
            InvensysFSR_LastName
            InvensysFSR_Phone
            InvensysFSR_Email

         }
       }
      `,
    });

    siteDetailsQuery.valueChanges.subscribe((result) => {
      const resultData = result && result.data;
      const siteDetails = resultData && resultData.getRemoteServicesSiteDetail;
      sessionStorage.setItem(SessionVariables.siteDetails, JSON.stringify(siteDetails));
      this.service.getSiteDetails.next(true);
      },
      (err) => {
      const errorMessage = err.message;
      if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
        //code to refresh token and to call again
        this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
          (result) => {
            const data = result.data.renewToken;
            if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
              // probably should logout the user
            } else if (data.JWT) {
              sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
              const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
              updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
              updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
              updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
              updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
              sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
              this.getSiteDetails(siteId);
            }
          })
      }
    });
  }

  public async updateRemoteServicesUsers(userCode: number, siteId: any, operation: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let ip = sessionStorage.getItem(SessionVariables.ip);
      ip = ip ? ip : '';
      const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
      let screenId = '';
      this.apollo.mutate({
        mutation: gql`
      mutation {
        updateRemoteServicesUsers(input: {
          user_code:${userCode},
          siteId: "${siteId}",
          operation: "${operation}",
          JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}"
        }
      )
    }
    `,
      }).subscribe(data => {
        resolve('');
      },
        (err) => {
          const errorMessage = err.message;
          if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
            //code to refresh token and to call again
            this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
              (result) => {
                const data = result.data.renewToken;
                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                  // probably should logout the user
                } else if (data.JWT) {
                  sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                  const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                  updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                  updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                  updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                  updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                  sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                  this.updateRemoteServicesUsers(userCode, siteId, operation);
                }
              })
          }
        });
    });
  }

  public getRemoteServicesTopStations(checkDate: any, period: number, siteId: any, severity: any
    ,alarmDesc: any, rwsMessageId: number): void {
    let remoteServicesTopStationsQuery: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    let severityParameter=(severity == null) ?`${severity}`:`"${severity}"`;
    let alarmDescParameter=(alarmDesc == null) ?`${alarmDesc}`:`"${alarmDesc}"`;
    remoteServicesTopStationsQuery = this.apollo.watchQuery({
      query: gql`query{
        getRemoteServicesTopStations(input:{
          checkDate: "${checkDate}",
          period: ${period},
          siteId: "${siteId}",
          severity: ${severityParameter},
          alarmDesc: ${alarmDescParameter},
          rwsMessageId: ${rwsMessageId},
          JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}"
        }){
          topStations {
            Letterbug
            num
          }
         }
       }
      `,
    });

    remoteServicesTopStationsQuery.valueChanges.subscribe((result) => {
      const resultData = result && result.data;
      const remoteServicesTopStations = resultData && resultData.getRemoteServicesTopStations;
      sessionStorage.setItem(SessionVariables.remoteServicesTopStations, JSON.stringify(remoteServicesTopStations));
      this.service.getRemoteServicesTopStations.next(true);
      },
      (err) => {
      const errorMessage = err.message;
      if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
        //code to refresh token and to call again
        this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
          (result) => {
            const data = result.data.renewToken;
            if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
              // probably should logout the user
            } else if (data.JWT) {
              sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
              const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
              updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
              updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
              updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
              updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
              sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
              this.getRemoteServicesEmails(siteId);
            }
          })
      }
    });
  }

  public getRemoteServicesTopMessages(checkDate: any, period: number, siteId: any, severity: any
    ,alarmDesc: any, rwsMessageId: number): void {
    let remoteServicesTopMessagesQuery: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    let severityParameter=(severity == null) ?`${severity}`:`"${severity}"`;
    let alarmDescParameter=(alarmDesc == null) ?`${alarmDesc}`:`"${alarmDesc}"`;
    remoteServicesTopMessagesQuery = this.apollo.watchQuery({
      query: gql`query{
        getRemoteServicesTopMessages(input:{
          checkDate: "${checkDate}",
          period: ${period},
          siteId: "${siteId}",
          severity: ${severityParameter},
          alarmDesc: ${alarmDescParameter},
          rwsMessageId: ${rwsMessageId},
          JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}"
        }){
          topMessages {
            RwsMsgID
            RwsMsgLabel
            num
          }
         }
       }
      `,
    });

    remoteServicesTopMessagesQuery.valueChanges.subscribe((result) => {
      const resultData = result && result.data;
      const remoteServicesTopMessages = resultData && resultData.getRemoteServicesTopMessages;
      sessionStorage.setItem(SessionVariables.remoteServicesTopMessages, JSON.stringify(remoteServicesTopMessages));
      this.service.getRemoteServicesTopMessages.next(true);
      },
      (err) => {
      const errorMessage = err.message;
      if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
        //code to refresh token and to call again
        this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
          (result) => {
            const data = result.data.renewToken;
            if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
              // probably should logout the user
            } else if (data.JWT) {
              sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
              const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
              updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
              updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
              updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
              updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
              sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
              this.getRemoteServicesEmails(siteId);
            }
          })
      }
    });
  }

  public getRemoteServicesEventMessages(checkDate: any, period: number, siteId: any, severity: any
    ,alarmDesc: any, rwsMessageId: number, sort: number): void {
    let remoteServicesEventMessagesQuery: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    let severityParameter=(severity == null) ?`${severity}`:`"${severity}"`;
    let alarmDescParameter=(alarmDesc == null) ?`${alarmDesc}`:`"${alarmDesc}"`;
    remoteServicesEventMessagesQuery = this.apollo.watchQuery({
      query: gql`query{
        getRemoteServicesEventMessages(input:{
          checkDate: "${checkDate}",
          period: ${period},
          siteId: "${siteId}",
          severity: ${severityParameter},
          alarmDesc: ${alarmDescParameter},
          rwsMessageId: ${rwsMessageId},
          sort: ${sort},
          JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}"
        }){
          eventMessages {
            severity
            AlphaCode
            RwsServerAlphaCode
            RwsSiteAlphaCode
            AlarmDateStamp
            AlarmTimeStamp
            AlarmDescription
            StatusMsgProcessed
            StatusCaseCreated
            StatusMsgSent
            SiteName
            RwsMsgTextLabel
            RwsMsgId
          }
         }
       }
      `,
    });

    remoteServicesEventMessagesQuery.valueChanges.subscribe((result) => {
      const resultData = result && result.data;
      const remoteServicesEventMessages = resultData && resultData.getRemoteServicesEventMessages;
      sessionStorage.setItem(SessionVariables.remoteServicesEventMessages, JSON.stringify(remoteServicesEventMessages));
      this.service.getRemoteServicesEventMessages.next(true);
      },
      (err) => {
      const errorMessage = err.message;
      if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
        //code to refresh token and to call again
        this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
          (result) => {
            const data = result.data.renewToken;
            if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
              // probably should logout the user
            } else if (data.JWT) {
              sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
              const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
              updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
              updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
              updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
              updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
              sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
              this.getRemoteServicesEmails(siteId);
            }
          })
      }
    });
  }

  public getMessageInfo(RwsEventMsgID: number): void {
    let messageInfoQuery: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    messageInfoQuery = this.apollo.watchQuery({
      query: gql`query{
        getRemoteServicesMessageInfo(input:{
          JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}",
          RwsEventMsgID: ${RwsEventMsgID}
        }){
          RwsEventMsgGroupName
          RwsEventMsgLabel
          RwsEventMsgGroupDesc
          RwsEventMsgDesc
          RwsEventMsgUrl
          RwsEventMsgID
         }
       }
      `,
    });

    messageInfoQuery.valueChanges.subscribe((result) => {
      const resultData = result && result.data;
      const messageInfo = resultData && resultData.getRemoteServicesMessageInfo;
      sessionStorage.setItem(SessionVariables.messageInfo, JSON.stringify(messageInfo));
      this.service.getMessageInfo.next(true);
      },
      (err) => {
      const errorMessage = err.message;
      if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
        //code to refresh token and to call again
        this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
          (result) => {
            const data = result.data.renewToken;
            if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
              // probably should logout the user
            } else if (data.JWT) {
              sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
              const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
              updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
              updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
              updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
              updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
              sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
              this.getMessageInfo(RwsEventMsgID);
            }
          })
      }
    });
  }

}
