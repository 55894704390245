import { Injectable } from '@angular/core';




import {
  SecretsManagerClient,
  GetSecretValueCommand,
  SecretsManager,
} from "@aws-sdk/client-secrets-manager";



@Injectable({
  providedIn: 'root'
})
export class AwsSecretManagerService {
   region="us-east-1"
   constructor() { }


  private client = new SecretsManagerClient({
    region: this.region,
  });


  

  public async getSecret(secretId: string) {
    //debugger
    let response;

    try {
      response = await this.client.send(
        new GetSecretValueCommand({
          SecretId: secretId,
          VersionStage: "AWSCURRENT", // VersionStage defaults to AWSCURRENT if unspecified
        })
      );
    } catch (error) {
      // For a list of exceptions thrown, see
      // https://docs.aws.amazon.com/secretsmanager/latest/apireference/API_GetSecretValue.html
      throw error;
    }

    const secret = response.SecretString;



    return secret;
  }
}
