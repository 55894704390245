import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, ErrorCodes } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';

@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlQuickLinksServiceSecure {

  constructor(private apollo: Apollo, public service: DynamicComponentCreateService, private gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure) { }

  public getQuickLink(fedId: string): any {
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    let quicklinkProfileQuery: QueryRef<any>;
    const loggedInUserDetails = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails)).data.getLoggedInUserInfo;
    const level_code = loggedInUserDetails.level_code;
    const userGroup = loggedInUserDetails.userGroup;
    quicklinkProfileQuery = this.apollo.watchQuery({
      query: gql`
      query{
        getQuickLinksDetailsSecure(input:{
          JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}",
          level_code: ${level_code},
          userGroup: ${userGroup}
        }){
          groups{
            groupId
            groupName
            links{
              title
              url
              isSubscribed
              linkId
            }
          }
        }
      }`,
      fetchPolicy: 'network-only'
    });
    quicklinkProfileQuery.valueChanges.subscribe((result) => {
      const resultData = result && result.data;
      const groups = resultData && resultData.getQuickLinksDetailsSecure && resultData.getQuickLinksDetailsSecure.groups;
      sessionStorage.setItem(SessionVariables.quickLinks, JSON.stringify(groups));
      this.service.getquicklinkservice.next(true);
    },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.getQuickLink(fedId);
              }
            })
        }
      }
    );
  }

  public updateQuickLink(fedId: string, quickLinkDataList: string): void {
    debugger;
    const loggedInUserDetails = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails)).data.getLoggedInUserInfo;
    const userCode = loggedInUserDetails.user_code;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    this.apollo.mutate({
      mutation: gql`
    mutation{
      setQuickLinksDetailsSecure(input:{
        JWT: "${sessionToken}",
        screenId: "${screenId}",
        ipAddress: "${ip}"
        groups: ${quickLinkDataList}
      }) 
    }`,
    })
      .subscribe(dataItem => {

      },
        (err) => {
          const errorMessage = err.message;
          if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
            //code to refresh token and to call again
            this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
              (result) => {
                const data = result.data.renewToken;
                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                  // probably should logout the user
                } else if (data.JWT) {
                  sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                  const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                  updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                  updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                  updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                  updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                  sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                  this.updateQuickLink(fedId, quickLinkDataList);
                }
              })
          }
        });
  }

}
