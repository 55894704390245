import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables } from '../constants';
import { CaseListType } from '../../gcs.types/gcs.types.CaseListType';
import { DynamicComponentCreateService } from '../gcs.business.services/dynamic-component-create.service';
@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlSessionCacheService {
  // Currently just a copy of GetCase List, but modify it make a real Session Cache GraphQL call -Anand
  constructor(private apollo: Apollo, public service: DynamicComponentCreateService) { }

  public getSessionCacheData(fedId: string, tabIndex: number): any {
    let caseListQuery: QueryRef<any>;
    let caseResult: any;
    // get the fed Id and stick in inside line 22 deferated
    caseListQuery = this.apollo.watchQuery({
      query: gql`
        query caseListQuery {
          getCaseList(
            input: {
              idmsFederatedId: "${fedId}"
              isMyCase: ${tabIndex}
            }
          ) {
            paAccountSEAccountId
            CaseDueStatusColor
            caseTypeName
            accountName
            caseId
            caseNumber
            createdDate
            cust_cont_id
            customerStatus
            customer_code
            description
            paCaseType
            paHotCase
            paTargetDate
            product
          }
        }
      `,
    });

    // sessionCache variable replaced, below. Replace rest of the logic - Anand
    caseListQuery.valueChanges.subscribe((result) => {
      result.data.getCaseList.map((row) => {
        row.description = row.description.length > 150 ? row.description.substring(0, 150) + ('...') : row.description;
      });
      if (tabIndex === 1) {
        sessionStorage.setItem(SessionVariables.sessionCache, JSON.stringify(this.convertCaseListDataToDate(result.data.getCaseList)));
        caseResult = sessionStorage.getItem(SessionVariables.sessionCache);
      } else if (tabIndex === 0) {
        sessionStorage.setItem(SessionVariables.sessionCache, JSON.stringify(this.convertCaseListDataToDate(result.data.getCaseList)));
        caseResult = sessionStorage.getItem(SessionVariables.sessionCache);
      }
      this.service.caseList.next(true);
    });
  }

  private convertCaseListDataToDate(inputCaseListData: any[]): CaseListType[] {
    const caseListData: CaseListType[] = [];
    for (const item of inputCaseListData) {
      caseListData.push({
        paAccountSEAccountId: item.paAccountSEAccountId,
        CaseDueStatusColor: item.CaseDueStatusColor,
        accountName: item.accountName,
        caseId: item.caseId,
        createdDate: item.createdDate,
        // above substring because the date is send with HH:mm:ss from back end, we are stripping it to keep YYYY-MM-DD(first 10 chars)
        caseNumber: item.caseNumber,
        cust_cont_id: item.cust_cont_id,
        customerStatus: item.customerStatus,
        customer_code: item.customer_code,
        description: item.description,
        paCaseType: item.paCaseType,
        paTargetDate: item.paTargetDate,
        product: item.product,
        paHotCase: item.paHotCase
      });
    }
    return caseListData;
  }
}
