import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { SeWebModule } from '@se/web-ui-angular';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { DataService, DropDownListModule } from '@progress/kendo-angular-dropdowns';
// import { DropDownListFilterComponent } from './dropdownlist-filter.component';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { SortableModule } from '@progress/kendo-angular-sortable';
import { PopupModule } from '@progress/kendo-angular-popup';
// import { EditorModule } from '@progress/kendo-angular-editor';
import 'hammerjs';
// import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { DynamicComponentCreateService } from './gcs.services/gcs.business.services/dynamic-component-create.service';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { LandingComponent } from './landing/landing.component';
import { GcsAppInitService } from './gcs.services/gcs.business.services/gcs.app-init.service';
import{AwsSecretManagerService} from "./gcs.services/gcs.business.services/aws-secret-manager.service"
import { GraphQLModule } from './graphql.module'

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SeWebModule.forRoot(),
    HttpClientModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    GridModule,
    PDFModule,
    ExcelModule,
    ChartsModule,
    InputsModule,
    DateInputsModule,
    TooltipModule,

    DropDownListModule,
    WindowModule,
    DialogModule,
    ButtonsModule,
    // SortableModule,
    FormsModule,
    ButtonsModule,
    PopupModule,
    LayoutModule,
    ReactiveFormsModule,
    LabelModule,
    // EditorModule
    GraphQLModule
  ],
  providers: [DynamicComponentCreateService, DataService, GcsAppInitService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(apollo: Apollo,private awssecrets:AwsSecretManagerService) {

   try {
    var secret = this.awssecrets.getSecret(environment.secretApiKey)
    // console.log(secret)

   } catch (error) {
    debugger
    console.error(error)

   }
    // moved environment based constant logic to constants.ts inside assets folder
    // const appsyncClient: any = new AWSAppSyncClient({
    //   disableOffline: true,
    //   url: environment.endPoint,
    //   region: environment.region,
    //   auth: {
    //     type: AUTH_TYPE.API_KEY,
    //     apiKey: atob(environment.samir),
    //   },
    // });

    // apollo.client = appsyncClient;
  }
}
