import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, ErrorCodes } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';
@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlNotificationsAndAlertsServiceSecure {

  constructor(private apollo: Apollo, public service: DynamicComponentCreateService, private gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure) { }

  public getNotificationsAndAlerts(userCode: number): any {
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    let screenId = '';
    let notificationQuery: QueryRef<any>;
    notificationQuery = this.apollo.watchQuery({
      query: gql`query {
        getNotificationsAndAlertsSecure(input: {
            JWT: "${sessionToken}",
            screenId: "${screenId}",
            ipAddress: "${ip}"
        }) {
         newsItemTitle
          newsItemSummary
          pointer
          brandName
          reference
          newsCatName
          updatedate
        }
      }
      `,
    });
    notificationQuery.valueChanges.subscribe(
      (result) => {
        sessionStorage.setItem(SessionVariables.notificationsAndAlerts, JSON.stringify(result.data.getNotificationsAndAlertsSecure));
        this.service.notificationAndAlerts.next(Math.random());
      },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.getNotificationsAndAlerts(userCode);
              }
            })
        }
      }
    );
  }

  public getAllNotificationsAndAlerts(userCode: number, getAllNotifications: boolean, title: string,
    summary: string, product_family: number,  override_user_selected: number): any {
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    let screenId = '';
    // console.log('input: ', `JWT: ${sessionToken}, screenId: ${screenId}, ipAddress: ${ip}, getAllNotifications: ${getAllNotifications}, title: ${title}, summary: ${summary}, product_family: ${product_family}, override_user_selected: ${override_user_selected}`);
    let notificationQuery: QueryRef<any>;
    notificationQuery = this.apollo.watchQuery({
      query: gql`query {
        getNotificationsAndAlertsSecure(input: {
            JWT: "${sessionToken}",
            screenId: "${screenId}",
            ipAddress: "${ip}",
            getAllNotifications: ${getAllNotifications},
            title: "${title}",
            summary: "${summary}",
            product_family: ${product_family},
            override_user_selected: ${override_user_selected}
        }) {
         newsItemTitle
          newsItemSummary
          pointer
          brandName
          reference
          newsCatName
          updatedate
        }
      }
      `,
    });
    notificationQuery.valueChanges.subscribe(
      (result) => {
        sessionStorage.setItem(SessionVariables.allNotificationsAndAlerts, JSON.stringify(result.data.getNotificationsAndAlertsSecure));
        this.service.allNotificationAndAlerts.next(Math.random());
      },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.getAllNotificationsAndAlerts(userCode, getAllNotifications, title,
                  summary, product_family,  override_user_selected);
              }
            })
        }
      }
    );
  }

  public getBellNotificationsAndAlerts(userCode: number): any {
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    let screenId = '';
    let bellNotificationQuery: QueryRef<any>;
    bellNotificationQuery = this.apollo.watchQuery({
      query: gql`query {
        getBellNotificationDetails(input: {
            JWT: "${sessionToken}",
            screenId: "${screenId}",
            ipAddress: "${ip}"
        }) {
          newsItemTitle
          newsItemSummary
          reference
          newsCatName
          pointer
          updatedate
          newsID
          newsItemDate
          contentFile
          submitterName
          submitterEmail
          isRead
        }
      }
      `,
    });
    bellNotificationQuery.valueChanges.subscribe(
      (result) => {
        sessionStorage.setItem(SessionVariables.bellNotificationsAndAlerts, JSON.stringify(result.data.getBellNotificationDetails));
        this.service.bellNotificationAndAlerts.next(true);
      },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.getBellNotificationsAndAlerts(userCode);
              }
            });
        }
      }
    );
  }
  public updateBellNotificationsAndAlerts(readNotificationArray: any): any {
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    let screenId = '';
    this.apollo.mutate({
      mutation: gql`
      mutation{
        setBellNotification(input:{
          JWT: "${sessionToken}",
          screenId:"${screenId}",
          ipAddress:"${ip}",
          bellNotificationReadList:[${readNotificationArray}]
        })
      }
    `,
    }).subscribe((data: any) => {
      sessionStorage.setItem(SessionVariables.updatedNotificationData, JSON.stringify(data.setBellNotification));
      this.service.updateBellNotificationAndAlerts.next(true);
    },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          // code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.updateBellNotificationsAndAlerts(readNotificationArray);
              }
            });
        }
      });
  }
}
