import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable, NgZone } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, ErrorCodes, TimedOutMessage } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';
import { DialogService } from "@progress/kendo-angular-dialog";

@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlGCSUsersServiceSecure {

  constructor(private apollo: Apollo, private service: DynamicComponentCreateService, private gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure,
    private dialogService: DialogService, private ngZone: NgZone
  ) { }

  public getGCSUsers(userCode: number): any {
    let gcsUsersQuery: QueryRef<any>;
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    let screenId = '';
    gcsUsersQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getGCSUsersSecure(input: 
            {
                JWT: "${sessionToken}",
                screenId: "${screenId}",
                ipAddress: "${ip}"
            })
             {
          GCSUsersList {
            company
            dateRegistered
            emailNotification
            lastVisit
            level
            location
            status
            user
            userEmail
          }
          companyList
        }
      }
      `,
    });
    gcsUsersQuery.valueChanges.subscribe((result) => {
      const GCSUsersList = result.data.getGCSUsersSecure.GCSUsersList;
      const companyList = result.data.getGCSUsersSecure.companyList;
      const makeObject = {
        GCSUsersList,
        companyList
      };
      sessionStorage.setItem(SessionVariables.gcsUsers, JSON.stringify(makeObject));
      this.service.gcsUsersGrid.next(true);
    },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.getGCSUsers(userCode);
              }
            })
        }
        // If execution time out error then show popup and stop spinning
        else if (errorMessage.includes(ErrorCodes.Execution_TimeOut)) {
          this.handleTimeOutError();
          this.addTimeoutLog();
          sessionStorage.setItem(SessionVariables.gcsUsers, JSON.stringify([]));
          this.service.gcsUsersGrid.next(true);
        }
      });
  }
  public handleTimeOutError() {
    this.ngZone.run(() => {
      this.dialogService
        .open({
          title: TimedOutMessage.titleMessage,
          content: TimedOutMessage.timedOutMessage,
          actions: [{ text: "OK", primary: true }],
          width: 450,
          height: 200,
          minWidth: 250,
        })
        .result.subscribe(result => {
          console.log("OK");
          // console.log(result);
        });
    });
  }
  public addTimeoutLog() {
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    let screenId = '';
    let addLogQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery{
        addToLog(input:{
          APIName: "getGCSUsersSecure",
          JWT: "${sessionToken}",
          ipAddress: "${ip}",
          logMessage: "${TimedOutMessage.logMessage}",
          logType: "${TimedOutMessage.logType}",
          screenId: "${screenId}"
        })
      }
      `
    });
    addLogQuery.valueChanges.subscribe(
      (result) => {
      }
    )
  }
}
