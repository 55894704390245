import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable, NgZone } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, ErrorCodes, TimedOutMessage } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';
import { DialogService } from "@progress/kendo-angular-dialog";

@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlRemoteServicesServiceSecure {

  constructor(
    private apollo: Apollo,
    private service: DynamicComponentCreateService,
    private gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure,
    private dialogService: DialogService, private ngZone: NgZone
  ) { }

  public getRemoteServices(userCode: number, localCompCode?: number): any {
    let remoteServicesQuery: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    remoteServicesQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getRemoteWatchSiteSecure(input: {
          localCompanyCode: ${localCompCode},
          JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}"
        }) {
          companyList
          remoteServiceSoftwareList
          remoteServiceTypeList
          siteList
          remoteWatchSiteList {
            AlphaCode
            Comments
            Company
            Country_Code
            Country_Desc
            Customer_Code
            GlobalCompany
            GlobalCompanyCode
            IA_Enabled
            PLC_Enabled
            Region_Code
            Region_Desc
            RemoteServicesCode
            RemoteServicesDesc
            RemoteServicesInstallDate
            RemoteServicesMcAfee
            RemoteServicesName
            RemoteServicesVersionDesc
            RemoteServicesWsus
            RemoteWatch_Enabled
            RwsServerAlphaCode1
            SiteEnabledCode
            SiteName
            Site_Code
            Triconex_Enabled
            RemoteWatchLink
          }
        }
      }
      `,
    });
    remoteServicesQuery.valueChanges.subscribe(
      (result) => {
        const regions = [];
        const companies = [];
        const regionName = [];
        const compName = [];
        let CompanyName = '';
        let updatedData;
        if (result.data.getRemoteWatchSiteSecure.remoteWatchSiteList && result.data.getRemoteWatchSiteSecure.remoteWatchSiteList.length >= 1) {
          result.data.getRemoteWatchSiteSecure.remoteWatchSiteList.forEach((row: any) => {
            if (row && row.Company && row.Company !== '') {
              CompanyName = row.Company;
            }
            regions.push(row.Country_Desc);
            regionName.push(row.Country_Desc);
            companies.push(row.Company);
            compName.push(CompanyName);
          });

          regions.map((val, index) => {
            if (regions.indexOf(val) !== index) {
              regions[index] = '';
            }
          });
          companies.map((val, index) => {
            if (companies.indexOf(val) !== index) {
              companies[index] = '';
            }
          });
          // result.data.getRemoteWatchSiteSecure.remoteWatchSiteList.map((row: any, index: any) => {
          //   row.Country_Desc = regions[index];
          //   row.Company = companies[index];
          //   row.CompName = compName[index];
          //   row.RegionName = regionName[index];
          // });
          updatedData = result.data.getRemoteWatchSiteSecure.remoteWatchSiteList.map((row: any, index: any) => {
            return {
                ...row,
                Country_Desc: regions[index],
                Company: companies[index],
                CompName: compName[index],
                RegionName: regionName[index]
              };
          });
        }
        const remoteWatchSiteList = updatedData || []; //result.data.getRemoteWatchSiteSecure.remoteWatchSiteList || [];
        const companyList = result.data.getRemoteWatchSiteSecure.companyList || [];
        const siteList = result.data.getRemoteWatchSiteSecure.siteList || [];
        const remoteServiceSoftwareList = result.data.getRemoteWatchSiteSecure.remoteServiceSoftwareList || [];
        const remoteServiceTypeList = result.data.getRemoteWatchSiteSecure.remoteServiceTypeList || [];

        const makeObject = {
          remoteWatchSiteList,
          siteList,
          companyList,
          remoteServiceSoftwareList,
          remoteServiceTypeList
        };

        sessionStorage.setItem(SessionVariables.remoteServices, JSON.stringify(makeObject));
        this.service.remoteServicesGrid.next(true);

      },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.getRemoteServices(userCode, localCompCode);
              }
            });
        }
        // If execution time out error then show popup and stop spinning
        else if (errorMessage.includes(ErrorCodes.Execution_TimeOut)) {
          this.handleTimeOutError();
          this.addTimeoutLog();
          sessionStorage.setItem(SessionVariables.remoteServices, JSON.stringify([]));
          this.service.remoteServicesGrid.next(true);
        }
      }
    );
  }
  public handleTimeOutError() {
    this.ngZone.run(() => {
      this.dialogService
        .open({
          title: TimedOutMessage.titleMessage,
          content: TimedOutMessage.timedOutMessage,
          actions: [{ text: "OK", primary: true }],
          width: 450,
          height: 200,
          minWidth: 250,
        })
        .result.subscribe(result => {
          console.log("OK");
          // console.log(result);
        });
    });
  }

  public addTimeoutLog() {
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    let screenId = '';
    let addLogQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery{
        addToLog(input:{
          APIName: "getRemoteWatchSiteSecure",
          JWT: "${sessionToken}",
          ipAddress: "${ip}",
          logMessage: "${TimedOutMessage.logMessage}",
          logType: "${TimedOutMessage.logType}",
          screenId: "${screenId}"
        })
      }
      `
    });
    addLogQuery.valueChanges.subscribe(
      (result) => {
      }
    )
  }
}
