import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, ErrorCodes } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { Router } from '@angular/router';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';
@Injectable({
  providedIn: 'root'
})
export class GcsGraphQlCaseListColumnsSecure {

  constructor(
    private apollo: Apollo,
    public service: DynamicComponentCreateService,
    public router: Router,
    public gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure
  ) { }

  public async getCaseListColumn(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let caseListColumnsQuery: QueryRef<any>;
      let ip = sessionStorage.getItem(SessionVariables.ip);
      ip = ip ? ip : '';
      const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
      let screenId = '';
      caseListColumnsQuery = this.apollo.watchQuery({
        query: gql`
      query{
        getCaseListColumn(input: {
          JWT: "${sessionToken}"
          screenId: "${screenId}",
          ipAddress: "${ip}"
        }) {
          columns {
            field
            title
            hidden
            order
          }
        }
      }
    `,
      });
      caseListColumnsQuery.valueChanges.subscribe((result) => {
        sessionStorage.setItem(SessionVariables.caseListTempColumns, JSON.stringify(result.data.getCaseListColumn.columns));
        resolve(result.data.getCaseListColumn.columns);
      },
        (err) => {
          const errorMessage = err.message;
          if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
            //code to refresh token and to call again
            this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
              (result) => {
                const data = result.data.renewToken;
                if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                  // probably should logout the user
                } else if (data.JWT) {
                  sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                  const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                  updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                  updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                  updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                  updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                  sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                  this.getCaseListColumn();
                }
              });
          }
        });
    });
  }
  public setCaseListColumn(caseListColumns: any): any {
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    this.apollo.mutate({
      mutation: gql`
      mutation{
        setCaseListColumn(input:{
          JWT: "${sessionToken}",
          screenId: "${screenId}",
          ipAddress: "${ip}",
          columns: ${caseListColumns}
        }) 
      }`
    }).subscribe((result) => {
    },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.setCaseListColumn(caseListColumns);
              }
            });
        }
      });
  }
}
