import { Apollo, QueryRef } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { SessionVariables, screenIdsForTelemetry, ErrorCodes } from '../../constants';
import { DynamicComponentCreateService } from '../../gcs.business.services/dynamic-component-create.service';
import { GcsGraphQlSessionIdServiceSecure } from './gcs.graph-ql.session-id.service.secure';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GcsGraphQlCaseFiltersServiceSecure {

  public allGcsAccountDetails: QueryRef<any>;
  public allGcsAccountDetailsSubscription: Subscription;
  public loading: boolean;

  constructor(
    private apollo: Apollo,
    public service: DynamicComponentCreateService,
    public gcsGraphQlSessionIdServiceSecure: GcsGraphQlSessionIdServiceSecure
  ) { }

  public getFilterDetails(fedId: string): any {
    let caseTypes: string[] = [];
    let companyDropDownList: string[] = [];
    let caseStatus: string[] = ['All'];
    let caseFiltersQuery: QueryRef<any>;
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = screenIdsForTelemetry['home-overview'].screenId;
    // get the fed Id and stick in inside line 22 deferated
    caseFiltersQuery = this.apollo.watchQuery({
      query: gql`
      query caseTypesQuery {
        getFiltersDetailsSecure(
          input: { 
            JWT: "${sessionToken}",
            screenId: "${screenId}",    
            ipAddress: "${ip}"
          }
        ) {
          status {
            caseType
            status
          }
          caseType
        }
      }
    `,
    });

    caseFiltersQuery.valueChanges.subscribe(
      (result) => {
        sessionStorage.setItem(SessionVariables.caseFilters, JSON.stringify(result.data.getFiltersDetailsSecure));
        caseTypes = result.data.getFiltersDetailsSecure.caseType;
        caseStatus = result.data.getFiltersDetailsSecure.status;
        companyDropDownList = result.data.getFiltersDetailsSecure.company;
        sessionStorage.setItem(SessionVariables.caseFilters, JSON.stringify(result.data.getFiltersDetailsSecure));
        this.service.caseFilters.next(true);
      },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.getFilterDetails(fedId);
              }
            })
        }
      }
    );
  }

  // get all the gcs account details with JWT token

  public getAllGCSAccountDetails(): void {
    let ip = sessionStorage.getItem(SessionVariables.ip);
    ip = ip ? ip : '';
    const sessionToken = sessionStorage.getItem(SessionVariables.sessionToken);
    let screenId = '';
    this.allGcsAccountDetails = this.apollo.watchQuery<any>({
      query: gql`
      query MyQuery {
        getAllGCSAccountDetailsSecure(input: {
          JWT: "${sessionToken}", 
          ipAddress: "${ip}", 
          screenId: "${screenId}"}) 
        {
          allGCSAccountDetails {
            SEAccountID
            LocalCompany
            CVCP
            bFOAccountID
            bFOContactID
            Customer_Contact_Number
            Email
            FirstName
            LastName
            LocalCompanyCode
          }
        }
      }
      
    `,
    });

    this.allGcsAccountDetailsSubscription = this.allGcsAccountDetails.valueChanges.subscribe(
      ({ data, loading }) => {
        const getData = data && data.getAllGCSAccountDetailsSecure;
        sessionStorage.setItem(SessionVariables.allGcsAccountDetails, JSON.stringify(getData.allGCSAccountDetails));
        this.loading = loading;
      },
      (err) => {
        const errorMessage = err.message;
        if (errorMessage.includes(ErrorCodes.JWT_EXPIRED)) {
          //code to refresh token and to call again
          this.gcsGraphQlSessionIdServiceSecure.getSessionId().subscribe(
            (result) => {
              const data = result.data.renewToken;
              if (data.message.includes(ErrorCodes.REFRESH_TOKEN_EXPIRED)) {
                // probably should logout the user
              } else if (data.JWT) {
                sessionStorage.setItem(SessionVariables.sessionToken, data.JWT);
                const updateSessionData = JSON.parse(sessionStorage.getItem(SessionVariables.loggedInUserDetails));
                updateSessionData.data.getLoggedInUserInfo.JWT = data.JWT;
                updateSessionData.data.getLoggedInUserInfo.JWTRefreshToken = data.refreshToken;
                updateSessionData.data.getLoggedInUserInfo.accessToken = data.accessToken;
                updateSessionData.data.getLoggedInUserInfo.apigeeAccessToken = data.apigeeAccessToken;
                sessionStorage.setItem(SessionVariables.loggedInUserDetails, JSON.stringify(updateSessionData));
                this.getAllGCSAccountDetails();
              }
            })
        }
      }
    );
  }

  public refresh(): void {
    this.allGcsAccountDetails.refetch();
  }

  ngOnDestroy(): void {
    this.allGcsAccountDetailsSubscription.unsubscribe();
  }

}
